import styled from "styled-components";
import { CommonColor } from "../utils/Color";
import { CommonFont } from "../utils/Font";
import { CommonString } from "../utils/CommonString";
import { keyframes } from "styled-components";
import { dividerClasses } from "@mui/material";
import { DeviceSizes } from "./common.style";

const FooterMainContainer = styled.div`
  background-color: ${CommonColor.black};
  position: sticky;
  bottom: 0;
  height: 100vh;
  display: flex;
  align-items: end;
  z-index: 0;
`;

const FooterContainer = styled.div`
  padding-bottom: 0;
  @media ${DeviceSizes.mobile} {
    padding-left: 19px;
    padding-right: 19px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 38px);
  }
`;
const BottomFooterContainer = styled.div`
  padding: 10px 0px;
  border-width: 0.5px;
  border-style: solid;
  border-image: ${CommonColor.footer_gradient_border};
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
  font-size: ${CommonFont.s};
  font-weight: 700;
  color: ${CommonColor.primary_color};
  margin-top: 20px;
  @media ${DeviceSizes.mobile} {
    /* padding: 10px 0; */
    flex-direction: column;
    /* margin-top: 20px; */
  }
`;
const SocialIconContainer = styled.div`
  display: none;
  justify-content: flex-start;
  align-items: center;
  @media ${DeviceSizes.mobile} {
    display: flex;
    margin-top: 50px;
  }
`;
const BottomFooterMenuContainer = styled.div`
  display: flex;
  a {
    margin-left: 20px;
    color: ${CommonColor.grey};
    text-decoration: underline;
  }
`;
const FooterText = styled.p`
  margin-block-start: 0;
  margin-block-end: 0px;
  color: ${(props) =>
    props.is_bold === "true" ? CommonColor.white : CommonColor.grey};
  font-weight: ${(props) => (props.is_bold === "true" ? "600" : "normal")};
  font-size: ${CommonFont.n};
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vil};
  }
`;
const FooterTextWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & span {
    margin-left: 2px;
    color: white;
  }
`;
const FooterIcon = styled.img`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  margin-left: 5px;
  margin-bottom: 10px;
`;
const ContactTitle = styled.p`
  font-size: ${CommonFont.m};
  font-weight: bold;
  width: max-content;
  color: ${CommonColor.grey};
  display: flex;
  @media ${DeviceSizes.mobile} {
    padding: 0;
    font-size: ${CommonFont.l};
  }
`;
const LetsCollaborateText = styled.p`
  font-size: ${CommonFont.xxl};
  font-weight: bold;
  color: ${CommonColor.white};
  margin-block-start: 0;
  margin-block-end: 0px;
  font-family: "Fahkwang", sans-serif;
  @media ${DeviceSizes.mobile} {
    font-size: calc(${CommonFont.xxxl} + 3em);
  }
`;
const LetsCollaborateTextContainer = styled.div`
  margin-bottom: 50px;
  padding: 0 ${CommonString.screen_padding};
  @media ${DeviceSizes.mobile} {
    padding: 0;
    margin-bottom: 50px;
  }
`;
var move = keyframes`
0%{bottom: 0px;    left: 0px;opacity:1 }
49% { bottom: 10px ;    left: 10px;opacity:0}
50% { bottom: -25px ;width: 0px;    left: -25px;opacity:0}
100% {bottom: 0px;width: 30px;    left: 0px;opacity:1 }
`;
var moveColor = keyframes`
0% { background: ${CommonColor.primary_color};}
100% {background: ${CommonColor.secondary_color}; }
`;
var ripple = keyframes`
0% { transform: scale(1); }
50% { transform: scale(1.2); opacity:1; }
100% { transform: scale(1.4); opacity:0; }
`;

const LetsCollaborateTextIconContainer = styled.div`
  background: ${CommonColor.secondary_color};
  // padding: 30px;
  cursor: pointer;

  border-radius: 50%;
  width: 75px;
  aspect-ratio: 1/1;
  margin-left: 17px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
    font-size: 10px;
    position: relative;
  }
  &:hover {
    /* animation: ${moveColor} 0.4s linear; */
  }
  &:hover img {
    animation: ${move} 0.6s ease-in-out;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid ${CommonColor.secondary_color};
    border-radius: 50%;
    animation: ${ripple} 2s linear infinite;
  }
  &:hover:before {
    animation: ${ripple} 2s linear 0;
  }
`;
const LetsCollaborateTextIcon = styled.img``;
const FooterBackgroundImg = styled.img`
  width: 100%;
  margin-top: -48px;
  margin-bottom: -25px;
`;
const GridContainer = styled.div`
  padding: 0 ${CommonString.screen_padding};
  margin-bottom: 7%;
  display: flex;
  justify-content: space-between;
  @media ${DeviceSizes.mobile} {
    padding: 0;
    margin-top: 37px;
    flex-direction: column;
    flex-wrap: wrap;
    flex-shrink: 0;
  }
`;
const FooterAnchorTag = styled.a`
  text-decoration: none;
  color: inherit;
`;
const SocialIconSingleContainer = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: ${CommonColor.icon_bg};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  a {
    color: initial;
  }
  @media ${DeviceSizes.mobile} {
    width: 41px;
    height: 41px;
  }
`;
const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${CommonFont.xs};
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 15.005px */
  letter-spacing: 0.2px;
  padding-left: 80px;

  span {
    text-decoration: none;
    color: #8e8e8e;
    margin-right: 20px;
    cursor: pointer;
  }
  @media ${DeviceSizes.mobile} {
    padding-left: 0;
    span {
      margin-right: 0;
      font-size: ${CommonFont.l};
    }
  }
`;
const ContactTitleContainer = styled.div`
  padding: 0 ${CommonString.screen_padding};
  width: calc(100% - 2 * ${CommonString.screen_padding});
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${DeviceSizes.mobile} {
    padding: 0;
    width: 100%;
  }
`;
const PageTopScroll = styled(FooterText)`
  color: ${CommonColor.grey};
  text-transform: capitalize;
  transition: color 0.5s ease-in-out, border 0.5s ease-in-out;
  cursor: pointer;
  border-bottom: 1px solid ${CommonColor.grey};
  padding-bottom: 5px;
  &:hover {
    color: ${CommonColor.white};
    border-bottom: 1px solid ${CommonColor.white};
  }
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.l};
  }
`;
const FooterTopWrapper = styled.div`
  @media ${DeviceSizes.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin-top: 66px;
  }
`;
export {
  FooterTopWrapper,
  PageTopScroll,
  ContactTitleContainer,
  FooterLinkContainer,
  SocialIconSingleContainer,
  FooterAnchorTag,
  FooterMainContainer,
  GridContainer,
  FooterBackgroundImg,
  LetsCollaborateTextIconContainer,
  LetsCollaborateTextIcon,
  LetsCollaborateTextContainer,
  LetsCollaborateText,
  ContactTitle,
  FooterIcon,
  FooterTextWithIcon,
  FooterContainer,
  BottomFooterContainer,
  BottomFooterMenuContainer,
  FooterText,
  SocialIconContainer,
};
