import Grid from "@mui/material/Unstable_Grid2";
import gsap from "gsap";
import React, { useEffect } from "react";
import {
  AboutCardStyled,
  AboutCardStyledHeader,
  AboutCardStyledHeading,
  AboutCardStyledText,
  AboutUsMission,
  AboutUsMissionText,
  AboutValueHeader,
  AboutValuesGridContainer,
  MissionSvg,
  VisionSvg,
} from "../../style/about.style";
import { Div, FlexContainer } from "../../style/common.style";
import { RecruitmentFeatureCard } from "../../style/recruitment.style";
import useScreenSize from "../../hooks/WindowSize";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export default function Vision() {
  const windowWidth = useScreenSize().width;
  useEffect(() => {
    if (windowWidth < 600) return;
    const cards = document.querySelectorAll(".card-recruitement");
    let last = 0;

    cards.forEach((card, i) => {
      if (i === 5) last = 0;
      gsap.set(card, {
        opacity: 0.25,
      });
      gsap.from(card, {
        opacity: 0.25,
        scrollTrigger: {
          trigger: card,
          start: "top center-=" + last,
          end: "top center-=" + (last + 70),
          markers: false,
          toggleActions: "play none none reverse",
          invalidateOnRefresh: true,
          scrub: true,
        },
        duration: 1,
      });
      gsap.to(card, {
        opacity: 1,
        scrollTrigger: {
          trigger: card,
          start: "top center-=" + last,
          end: "top center-=" + (last + 70),
          markers: false,
          toggleActions: "play none none reverse",
          invalidateOnRefresh: true,
          scrub: true,
        },
        duration: 1,
      });

      last += 70;
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  function movingCursor(event) {
    const cards = document.querySelectorAll(".card-recruitement");
    cards.forEach((card, index) => {
      gsap.to(card, {
        "--mouse-x": event.clientX - card.getBoundingClientRect().left + "px",
        "--mouse-y": event.clientY - card.getBoundingClientRect().top + "px",
      });
    });
  }
  return (
    <Div id="values-and-mission-container">
      <AboutValueHeader>Our Values</AboutValueHeader>
      <AboutValuesGridContainer onMouseMove={(e) => movingCursor(e)}>
        <Grid container spacing={{ xs: 2, sm: 2 }}>
          <Grid item xs={12} sm={2.4} className="grid-feature-values">
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                height: "300px",
                width: "100%",
                perspective: "1000px",
                transformStyle: "preserve-3d",
                marginBottom: 0,
              }}
            >
              <div className="card-border"></div>
              <AboutCardStyled>
                {/* <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              /> */}
                <AboutCardStyledHeader>01</AboutCardStyledHeader>
                <AboutCardStyledHeading>
                  People Over Profit
                </AboutCardStyledHeading>
                <AboutCardStyledText>
                  <span className="animating-nums">
                    Prioritizing humanity, we embrace ethical practices over
                    profit, ensuring community and environmental well-being
                    align with business success.
                  </span>
                </AboutCardStyledText>
              </AboutCardStyled>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={2.4} className="grid-feature-values">
            <RecruitmentFeatureCard
              className="card-recruitement"
              onMouseMove={(e) => movingCursor(e)}
              style={{
                height: "300px",
                width: "100%",
                marginBottom: 0,
                marginBottom: 0,
              }}
            >
              <div className="card-border"></div>
              <AboutCardStyled>
                {/* <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              /> */}
                <AboutCardStyledHeader>02</AboutCardStyledHeader>
                <AboutCardStyledHeading>
                  Service over Sales
                </AboutCardStyledHeading>
                <AboutCardStyledText>
                  <span className="animating-nums">
                    Fostering lasting relationships through exceptional service,
                    surpassing mere transactions for a truly customer-centric
                    business approach
                  </span>
                </AboutCardStyledText>
              </AboutCardStyled>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={2.4} className="grid-feature-values">
            <RecruitmentFeatureCard
              className="card-recruitement"
              onMouseMove={(e) => movingCursor(e)}
              style={{ height: "300px", width: "100%", marginBottom: 0 }}
            >
              <div className="card-border"></div>
              <AboutCardStyled>
                {/* <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              /> */}
                <AboutCardStyledHeader>03</AboutCardStyledHeader>
                <AboutCardStyledHeading>
                  Virtue over Victory
                </AboutCardStyledHeading>
                <AboutCardStyledText>
                  <span className="animating-nums">
                    Championing integrity and ethics, we prioritize moral
                    excellence over mere triumph, shaping a conscientious and
                    principled enterprise
                  </span>
                </AboutCardStyledText>
              </AboutCardStyled>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={2.4} className="grid-feature-values">
            <RecruitmentFeatureCard
              className="card-recruitement"
              onMouseMove={(e) => movingCursor(e)}
              style={{ height: "300px", width: "100%", marginBottom: 0 }}
            >
              <div className="card-border"></div>
              <AboutCardStyled>
                {/* <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              /> */}
                <AboutCardStyledHeader>04</AboutCardStyledHeader>
                <AboutCardStyledHeading>
                  Quality over quantity
                </AboutCardStyledHeading>
                <AboutCardStyledText>
                  <span className="animating-nums">
                    Embracing ethical values, we prioritize quality, valuing
                    integrity over mere quantity.
                  </span>
                </AboutCardStyledText>
              </AboutCardStyled>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={2.4} className="grid-feature-values">
            <RecruitmentFeatureCard
              className="card-recruitement"
              onMouseMove={(e) => movingCursor(e)}
              style={{ height: "300px", width: "100%", marginBottom: 0 }}
            >
              <div className="card-border"></div>
              <AboutCardStyled>
                {/* <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              /> */}
                <AboutCardStyledHeader>05</AboutCardStyledHeader>
                <AboutCardStyledHeading>
                  Collaboration over Competition
                </AboutCardStyledHeading>
                <AboutCardStyledText>
                  <span className="animating-nums">
                    Embracing cooperative synergy, we unite expertise for
                    collective success, valuing shared goals above individual
                    rivalry.
                  </span>
                </AboutCardStyledText>
              </AboutCardStyled>
            </RecruitmentFeatureCard>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={{ xs: 2, sm: 2 }}
          onMouseMove={(e) => movingCursor(e)}
        >
          <Grid item xs={12} sm={6}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                height: windowWidth < 600 ? "max-content" : "250px",
                width: "100%",
                marginTop: windowWidth < 600 ? "8px" : "6px",
              }}
            >
              <div className="card-border"></div>
              <AboutCardStyled
                style={{
                  flexDirection: window < 600 ? "column" : "row",
                  justifyContent: "space-evenly",
                }}
              >
                <FlexContainer
                  verticalAlign="start"
                  width="max-content"
                  jc={windowWidth < 600 ? "start" : "initial"}
                >
                  <MissionSvg />
                </FlexContainer>
                <FlexContainer
                  flexDirection="column"
                  jc="start"
                  verticalAlign="start"
                  width="max-content"
                  margin={windowWidth < 600 ? "0 0 0 3%" : "0 5% 0 20%"}
                >
                  <AboutUsMission>Our Mission</AboutUsMission>
                  <AboutUsMissionText>
                    To Promote Expand Elevate people and business
                  </AboutUsMissionText>
                </FlexContainer>
              </AboutCardStyled>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                height: windowWidth < 600 ? "max-content" : "250px",
                width: "100%",
                marginTop: windowWidth < 600 ? 0 : "6px",
              }}
            >
              <div className="card-border"></div>
              <AboutCardStyled
                style={{ flexDirection: "row", justifyContent: "space-evenly" }}
              >
                <FlexContainer
                  verticalAlign="start"
                  width="max-content"
                  jc={windowWidth < 600 ? "start" : "initial"}
                >
                  <VisionSvg />
                </FlexContainer>
                <FlexContainer
                  flexDirection="column"
                  jc="start"
                  verticalAlign="start"
                  margin={windowWidth < 600 ? "0 0 0 3%" : "0 5% 0 20%"}
                >
                  <AboutUsMission>Our Vision</AboutUsMission>
                  <AboutUsMissionText>
                    To be the epitome of te best firm to collaborate with and
                    become an important building block of great success stories.
                  </AboutUsMissionText>
                </FlexContainer>
              </AboutCardStyled>
            </RecruitmentFeatureCard>
          </Grid>
        </Grid>
      </AboutValuesGridContainer>
    </Div>
  );
}
