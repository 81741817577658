import styled, { keyframes } from "styled-components";
import { CommonColor } from "../utils/Color";
import { CommonFont } from "../utils/Font";
import { DeviceSizes } from "./common.style";
import Menu from "@mui/material/Menu";
import { MenuItem, Popper } from "@mui/material";
import { TextContainer } from "../component/commonComponent/MagneticHeaderButton";
import { CommonImages } from "../utils/CommonImages";

const HeaderContainer = styled.div`
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${CommonColor.white};
  z-index: 4;
  padding-left: 29px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 80%
      ),
      url("your-background-image.jpg");
    background-size: cover;
    filter: blur(10px);
    scale: 1.22;
    overflow: hidden;
    z-index: -1;
  }
  /* backdrop-filter: blur(12px) brightness(0.7); */
  @media ${DeviceSizes.bigScreen} {
    width: 100%;
  }

  @media ${DeviceSizes.mobile} {
    padding-left: 2px;
    height: 67px;
    width: calc(99% + 1px);
  }
`;
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: visible;
  margin-right: 18px;
  @media ${DeviceSizes.mobile} {
    margin-right: 0px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  font-size: ${CommonFont.m};
  font-weight: 600;
  cursor: pointer;
  flex-direction: column;
  transition: color 0.5s ease-in-out;
  & svg {
    transform: ${(props) => (props.open ? "rotateX(0deg)" : "rotateX(180deg)")};
    transition: transform 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & path {
    transition: stroke 0.5s ease-in-out;
    stroke: ${(props) =>
      props.open ? CommonColor.secondary_color : CommonColor.white};
  }
  &:hover {
    color: ${CommonColor.secondary_color};
    & div {
      color: ${CommonColor.secondary_color};
    }
    & path {
      stroke: ${CommonColor.secondary_color};
    }
  }
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;
const HeaderContentMobile = styled(HeaderContent)`
  display: none;

  @media ${DeviceSizes.mobile} {
    display: block;
    margin-right: 9px;
    margin-top: 3px;
  }
`;
const HeaderBttonIconContainer = styled.div`
  margin: 0 7px 0px 0px;
`;
const IconContainerNav = styled.div`
  rotate: ${(props) => (props.isvisible ? "0deg" : "90deg")};
  transition: all 0.5s;
`;
const NavIconFirstContainer = styled.div`
  display: flex;
  position: relative;
  width: 25px;
  height: 2px;
  top: ${(props) => (props.isvisible ? "0" : "4.3px")};
  left: ${(props) => (props.isvisible ? "0" : "-0.3px")};
  translate: ${(props) => (props.isvisible ? "0" : "-0.5px 0px")};
  margin-bottom: 5px;
  & div:first-child {
    background-color: ${CommonColor.white};
    rotate: ${(props) => (props.isvisible ? "0deg" : "45deg")};
    height: 2px;
    width: 12px;
    translate: ${(props) => (props.isvisible ? "0" : "3.5px")};
    transition: ${(props) =>
      props.isvisible
        ? "rotate 0.5s ease-in-out, border-radius 0.5s ease-in-out, translate 0.5s ease-in-out"
        : "rotate 0.5s ease-in-out, border-radius 0.5s ease-in-out, translate 0s ease-in-out"};
    border-radius: ${(props) => (props.isvisible ? "0px" : "10px")};
  }
  & div:nth-child(2) {
    background-color: ${CommonColor.white};
    rotate: ${(props) => (props.isvisible ? "0deg" : "-45deg")};
    height: 2px;
    width: 12px;
    transition: ${(props) =>
      props.isvisible
        ? "rotate 0.5s ease-in-out, border-radius 0.5s ease-in-out, translate 0.5s ease-in-out"
        : "rotate 0.5s ease-in-out, border-radius 0.5s ease-in-out, translate 0s ease-in-out"};
    border-radius: ${(props) => (props.isvisible ? "0px" : "10px")};
  }
  @media ${DeviceSizes.mobile} {
    width: 22px;
  }
`;
const NavIconSecond = styled.div`
  height: 2px;
  width: ${(props) => (props.isvisible ? "24px" : "0px")};
  scale: ${(props) => (props.isvisible ? "1 1" : "0 1")};

  background-color: ${CommonColor.white};
  transition: ${(props) =>
    props.isvisible
      ? "scale 0.3s ease-out, width 0s ease-out"
      : "scale 0.3s ease-out, width 0s ease-out 0.3s"};
  @media ${DeviceSizes.mobile} {
    width: 22px;
  }
`;
const NavIconThirdContainer = styled.div`
  display: flex;
  position: relative;
  width: 25px;
  height: 2px;
  margin-top: 5px;
  top: ${(props) => (props.isvisible ? "0px" : "-2.5px")};
  & div:first-child {
    background-color: ${CommonColor.white};
    rotate: ${(props) => (props.isvisible ? "-0deg" : "-45deg")};
    height: 2px;
    width: 12px;
    translate: ${(props) => (props.isvisible ? "0" : "3px")};
    transition: ${(props) =>
      props.isvisible
        ? "rotate 0.5s ease-in-out, border-radius 0.5s ease-in-out, translate 0.5s ease-in-out"
        : "rotate 0.5s ease-in-out, border-radius 0.5s ease-in-out, translate 0s ease-in-out"};
    border-radius: ${(props) => (props.isvisible ? "0px" : "10px")};
  }
  & div:nth-child(2) {
    background-color: ${CommonColor.white};
    rotate: ${(props) => (props.isvisible ? "0deg" : "46deg")};
    translate: ${(props) => (props.isvisible ? "0" : "-1px")};
    height: 2px;
    width: 12px;
    transition: ${(props) =>
      props.isvisible
        ? "rotate 0.5s ease-in-out, border-radius 0.5s ease-in-out, translate 0.5s ease-in-out"
        : "rotate 0.5s ease-in-out, border-radius 0.5s ease-in-out, translate 0s ease-in-out"};
    border-radius: ${(props) => (props.isvisible ? "0px" : "10px")};
  }
  @media ${DeviceSizes.mobile} {
    width: 22px;
  }
`;
const MobileNav = styled.div`
  display: none;
  position: fixed;
  height: 100vh;
  top: 0px;
  width: 100%;
  background-color: black;
  z-index: 2;
  overflow: auto;
  padding-top: 82px;
  padding-left: 21px;
  padding-right: 21px;
  box-sizing: border-box;
  transform-origin: bottom;
  transform: ${(props) => (props.isvisible ? "scaleY(1)" : "scaleY(0)")};
  @media ${DeviceSizes.mobile} {
    display: block;
  }
`;
const TitleText = styled.div`
  font-size: ${CommonFont.ms};
  font-weight: 500;
  color: white;
  text-transform: capitalize;
  opacity: 0.4;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xil};
  }
`;
const MenuText = styled(TitleText)`
  font-size: ${CommonFont.m};
  font-weight: 500;
  margin-top: 20px;
  opacity: 1;

  &:has(svg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & svg {
    transform: ${(props) => (props.isvisible ? "rotateX(180deg)" : "")};
    transition: transform 0.5s;
  }
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xl};
    font-weight: ${(props) => (props.isOnPage ? "800" : "500")};
  }
`;
const ServiceMenuContainer = styled(MenuItem)``;
const ServiceMenuContainerMobile = styled.div`
  max-height: ${(props) => (props.isvisible ? "0" : "155px")};
  /* transform: ${(props) => (props.isvisible ? "scale(0)" : "scale(1)")}; */
  transition: max-height 0.5s ease-in-out, margin 0.5s ease-in-out;
  overflow: hidden;
  margin-top: ${(props) => (props.isvisible ? "" : "10px")};
  margin-left: 10px;
`;
const ServiceMenuText = styled(TitleText)`
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 12px;

  /* background: rgba(207, 207, 207, 0.2); */
  /* backdrop-filter: blur(12.5px); */

  transition: font-weight 0.5s ease-in-out;
  opacity: ${(props) => (props.isOnPage ? "1" : "0.7")};
  color: white;
  padding-left: 12px;
  margin-top: 2px;
  background-color: ${(props) =>
    props.isOnPage ? CommonColor.grey + "!important" : ""};
  width: calc(100% - 23px);
  & svg {
    position: relative;
    left: -10px;
    opacity: ${(props) => (props.isOnPage ? "0 !important" : "0")};
    transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  &:hover {
    background-color: ${CommonColor.dark_primary_color};
  }
  @media ${DeviceSizes.belowBigScreen} {
    font-size: ${CommonFont.m};
  }
  @media ${DeviceSizes.laptop} {
    font-size: ${CommonFont.s};
  }
  @media ${DeviceSizes.belowLaptop} {
    font-size: ${CommonFont.s};
  }
  @media ${DeviceSizes.bigScreen} {
    font-size: ${CommonFont.xs};
    /* font-size: clamp(${CommonFont.s}, ${CommonFont.s}, ${CommonFont.xs}); */
  }

  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vl};
    overflow: hidden;
  }
`;
const MenuIconContainer = styled(IconContainer)`
  margin: 0;
  & path {
    stroke: ${CommonColor.white};
    transition: stroke 0.5s ease-in-out;
  }
  &:hover {
    color: ${CommonColor.white};
    & path {
      stroke: ${CommonColor.white};
      transition: stroke 0.5s ease-in-out;
    }
  }
`;
const borderRotate = keyframes`
	100% {
		--angle: 420deg;
	}
`;
const ServiceMenuPositioner = styled(Menu)`
  && {
    z-index: 10;
    width: auto;
    & .MuiPaper-root.MuiMenu-paper {
      background-color: ${CommonColor.transparent};
      border-radius: 12px;
      max-width: unset !important;
    }
    & ul {
      display: flex;
      flex-direction: column;
      padding-left: 8px;
      padding-right: 8px;
      width: 278px;
      height: max-content;
      background-color: ${CommonColor.transparent};
      background-color: ${CommonColor.dropdown_bg};
      border-radius: 12px;
    }
    && .nav-service-text {
      height: 60px;
      flex-shrink: 0;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      box-sizing: border-box;
      margin-bottom: 5px;
      background-color: ${CommonColor.dropdown_text_bg_hover};
      transition: background-color 0.5s ease-in-out;
      border: 0.7px solid ${CommonColor.dropdown_text_bg};
      position: relative;
      width: 100%;
      /* margin-left: 8px; */
      --d: 2500ms;
      --angle: 90deg;
      --gradX: 100%;
      --gradY: 50%;
      --c1: rgba(168, 239, 255, 1);
      --c2: rgba(168, 239, 255, 0.1);

      &:hover {
        background-color: ${CommonColor.grey};
        backdrop-filter: blur(50px);
        border: 0.7px solid ${CommonColor.transparent};

        & svg {
          left: -6px;
          opacity: 1;
          overflow: visible;
          box-shadow: 0px -0px 10000px transparent;
        }
        /* border: 0rem solid; */
        /* border-image: conic-gradient(
            from var(--angle),
            var(--c2),
            var(--c1) 0.1turn,
            var(--c1) 0.15turn,
            var(--c2) 0.25turn
          )
          30; */
        /* animation: ${borderRotate} var(--d) linear infinite forwards; */
      }
    }
    && .nav-service-text:last-child {
      margin-bottom: 0;
    }
    @media ${DeviceSizes.mobile} {
      display: none;
    }
  }
`;
const HeaderTextContainer = styled(TextContainer)`
  color: ${(props) =>
    props.open ? CommonColor.secondary_color : CommonColor.white};
  transition: color 0.5s ease-in-out;
`;
const MenuHeaderBackground = styled(CommonImages.MenuHeaderBg)`
  position: absolute;
  z-index: -11;
  inset: -15px 0px 0px;
  margin: auto;
  width: calc(100% - 6px);
  height: calc(100% + 12px);
  backdrop-filter: blur(12px);
  scale: 1.01 1.12;
  border: none;
  outline: none;
`;
const PrexelIconContainer = styled.div`
  /* padding-left: 5%; */
  cursor: pointer;

  & svg {
    width: 100px;
  }
  @media ${DeviceSizes.mobile} {
    padding-left: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      width: 80px;
    }
  }
`;

export {
  PrexelIconContainer,
  MenuHeaderBackground,
  HeaderTextContainer,
  ServiceMenuContainerMobile,
  ServiceMenuPositioner,
  HeaderBttonIconContainer,
  HeaderContainer,
  HeaderContent,
  HeaderContentMobile,
  MenuIconContainer,
  IconContainer,
  IconContainerNav,
  MenuText,
  MobileNav,
  NavIconFirstContainer,
  NavIconSecond,
  NavIconThirdContainer,
  ServiceMenuContainer,
  ServiceMenuText,
  TitleText,
};
