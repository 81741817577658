import styled, { keyframes } from "styled-components";
import background from "../assets/images/PrivacyBg.svg";
import { CommonString } from "../utils/CommonString";
import { CommonColor } from "../utils/Color";
import { CommonFont } from "../utils/Font";

const size = {
  mobile: "600px",
  tablet: "912px",
  onek: "1024px",
  laptop: "1200px",
  bigScreen: "1409px",
};
const DeviceSizes = {
  mobile: `(max-width: ${size.mobile})`,
  belowtablet: `(max-width: ${size.tablet})`,
  abovetablet: `(min-width: ${size.tablet})`,
  belowonek: `(max-width: ${size.onek})`,
  laptop: `(min-width: ${size.laptop})`,
  belowLaptop: `(max-width: ${size.laptop})`,
  belowBigScreen: `(max-width: ${size.bigScreen})`,
  bigScreen: `(min-width: ${size.bigScreen})`,
};

const MainContainer = styled.div`
  // min-height: 100vh;
  /* scroll-behavior: smooth; */
  // overflow-x: hidden;
  position: relative;
  z-index: 1;
`;
const FirstContainer = styled.div`
  background-image: url(${background});
  padding: ${CommonString.screen_padding};
  padding-bottom: 0;
  // height: 100vh;
  padding-top: ${CommonString.main_screen_top_margin};
  z-index: 1;
  position: relative;
  border-bottom: 2px solid ${CommonColor.primary_color};
  background-size: cover;
`;
const Span = styled.span`
  color: ${CommonColor.secondary_color};
`;
var spin = keyframes`
0%   {transform: scale(1);}
50%  {transform: scale(1.04);}
100%  {transform: scale(1);}
`;
const CustomButton = styled.button`
  color: ${CommonColor.primary_color} !important;
  font-size: 14px;
  position: relative;
  display: block;
  overflow: hidden;
  width: auto;
  height: auto;
  border-radius: 20px;
  margin: 10px auto;
  text-align: center;
  text-decoration: none;
  padding: 15px 30px;
  border: 1px solid ${CommonColor.primary_color};
  border-radius: 25px;
  width: fit-content;
  font-weight: 600;
  letter-spacing: -0.54px;
  font-size: ${CommonFont.n};
  cursor: pointer;
  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: ${CommonColor.primary_color};
    -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    overflow: hidden;
  }
  &:hover {
    color: ${CommonColor.white} !important;
    animation-name: ${spin};
    animation-duration: 0.5s;
    transition-timing-function: ease-in-out;
    svg path {
      stroke: ${CommonColor.white} !important;
    }
  }
  &:hover span {
    width: 250%;
    height: 562.5px;
  }
  &:active {
    background-color: ${CommonColor.white};
    overflow: hidden;
  }
`;
const SocialName = styled.div`
  z-index: 2;
  position: relative;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.jc ?? "flex-start"};
  align-items: ${(props) => props.verticalAlign ?? "center"};
  flex-direction: ${(props) => props.flexDirection ?? "row"};
  width: ${(props) => props.width ?? "100%"};
  -webkit-width: ${(props) => props.webkitWidth ?? "100%"};
  -moz-width: ${(props) => props.safariWidth ?? "100%"};
  height: ${(props) => props.height};
  flex-wrap: ${(props) => props.flexWrap};
  margin: ${(props) => props.margin};
  row-gap: ${(props) => props.rowGap};
  column-gap: ${(props) => props.columnGap};
  flex: ${(props) => props.flex};
  background-color: ${(props) => props.bg};
  padding: ${(props) =>
    !props.padding?.lg ? props.padding : props.padding.lg};
  border: ${(props) => props.border};
  box-sizing: ${(props) => props.boxSizing};
  gap: ${(props) => props.gap};
  border-radius: ${(props) => props.borderRadius};
`;
const DividerLine = styled.div`
  background-color: ${CommonColor.grey};
  width: 100%;
  height: 1px;
`;

const ContactInput = styled.input`
  background-color: black;
  font-family: "Montserrat";
  outline: none;
  border-width: 0px;
  border-bottom-width: 1.4px;
  padding-top: 30px;
  margin-top: 40px;
  font-size: ${CommonFont.m};
  font-weight: 500;
  color: ${CommonColor.white};
  padding-bottom: 10px;

  transition: border 0.65s ease-in-out;

  &:hover {
    border-bottom-width: 1.4px;
    border-color: white;
  }
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xil};
  }
`;

const AttachmentTextContainer = styled.div`
  color: ${CommonColor.white};
  font-size: ${CommonFont.n};
  margin-left: 12px;
  border-bottom: 1px solid ${CommonColor.white};
  width: max-content;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vil};
  }
`;
const AttachmentDetailContainer = styled.div`
  display: flex;
  margin-top: 20px;
  cursor: pointer;
  flex-direction: column;
  & > div {
    display: flex;
    transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
    transform: translateX(0px);
  }
  & > div:hover {
    transform: translateX(5px);
    opacity: 0.8;
  }
`;
const AttachementInputContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  svg {
    rotate: 45deg;
  }
`;
const AttachmentName = styled(AttachmentTextContainer)`
  font-size: ${CommonFont.ms};
  border-bottom: none;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vil};
  }
`;
const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;
const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  z-index: 1000000;
`;
const LoaderInnerContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  display: flex;
  position: relative;
`;
const Loader = styled.div`
  width: calc(100vw / 3);
  height: 100%;
  background-color: ${CommonColor.secondary_color};
  /* bottom: 0; */
  left: ${(props) => `calc((100vw/3)*${props.$index})`};
  position: absolute;
`;
const Wrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
`;
const Div = styled.div``;
export {
  Div,
  Wrapper,
  Loader,
  LoaderInnerContainer,
  LoaderContainer,
  FileInput,
  AttachmentName,
  AttachementInputContainer,
  AttachmentDetailContainer,
  AttachmentTextContainer,
  ContactInput,
  DividerLine,
  DeviceSizes,
  MainContainer,
  FirstContainer,
  Span,
  CustomButton,
  SocialName,
  FlexContainer,
};
