import styled, { keyframes } from "styled-components";
import background from "../assets/images/background.svg";
import { CommonString } from "../utils/CommonString";
import { CommonFont } from "../utils/Font";
import { CommonColor } from "../utils/Color";
import { Button } from "@mui/material";
import { DeviceSizes } from "./common.style";
import { CommonImages } from "../utils/CommonImages";
import { Noise } from "../utils/Endpoint";

const HomeContainer = styled.div``;

const HomeFirstContainer = styled.div`
  background-image: url(${background});
  padding: ${CommonString.screen_padding};
  padding-bottom: 0;
  height: 100vh;
  background-position: center;
  background-size: cover;
  padding-top: ${CommonString.main_screen_top_margin};
  z-index: 1;
  position: relative;
`;
//first component
const WETitle = styled.h1`
  font-size: 15vw;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: -9vh;
  font-weight: bold;
  text-align: center;
  background: ${CommonColor.we_background_color};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${CommonColor.transparent};
  transition: ease-in-out 0.2s;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  // height: 15vh;
`;
const Title = styled.img`
  width: 80%;
  scroll-behavior: smooth;
  margin-bottom: 5vh;
  transition: ease-in-out 0.1s;
`;

const DotIcon = styled.img`
  margin: 0 8px;
  margin-left: 15px;
  width: 8px;
  margin-top: 10px;
  // transition: all 0.3s;
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${CommonColor.secondary_color};
  height: 10vh;
  position: absolute;
  left: 50%;
`;
const ServiceWrapper = styled.div`
  background-color: ${CommonColor.black};
  padding-top: 35vh;
  @media ${DeviceSizes.belowtablet} {
    padding: 0;
  }
`;
const ServicesTitle = styled.div`
  color: ${CommonColor.white};
  font-size: ${CommonFont.l};
  font-weight: 700;
  text-align: center;
  background-color: ${CommonColor.black};
  /* height: 23vh; */
  /* padding-top: 12px; */
  /* padding-bottom: 12px; */
  /* height: max-content !important; */
  overflow: visible;
  transition: opacity 0.5s ease-in-out;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 14vh !important;
  max-height: 14vh !important;
  transform-origin: top;
  /* padding-top: 14vh; */
  & .char {
    background-color: ${CommonColor.black};
  }
  & .line {
    height: max-content;
    background: black;
  }

  @media ${DeviceSizes.belowtablet} {
    height: max-content;
    display: flex;
    justify-content: center;
  }
  @media ${DeviceSizes.mobile} {
    padding-top: 2vh !important;
    font-size: ${CommonFont.xxxl};
  }
`;
const TitleWrapperContainer = styled.div`
  height: max-content;
  width: 100%;
`;
const DividerLine = styled.div`
  height: 100vh;
  width: 2px;
  background-color: ${CommonColor.white};
  margin: 20px 0 20px 0;
  margin-left: auto;
  margin-right: auto;
`;
const CardWrapper = styled.div`
  padding-bottom: 30vh;
  @media ${DeviceSizes.belowtablet} {
    /* padding-top: 5vh; */
    padding-bottom: 10vh;
  }
`;
const borderTravel = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const spin = keyframes`

to {
    --angle: 1turn;
  }
`;
const CardContainer = styled.div`
  height: calc(65vh - 4px);
  /* border-radius: 10px; */
  width: calc(100%-4px);
  margin: 2px;
  backdrop-filter: blur(103.05628967285156px) brightness(0.4);
  background-color: #d9d9d929;
  transform-origin: top;
  border-radius: 8px;
  & .svg-container {
    position: absolute;
    top: 0;
    left: 0;
  }
  @media ${DeviceSizes.mobile} {
    height: unset;
    border-radius: 30px;
  }
`;
const rotateGradient = keyframes`
  to { transform: rotate(360deg) }
`;
const CardMainContainer = styled.div`
  position: relative;
  /* background: linear-gradient(to right, #efefef, blue); */
  height: 65vh;
  /* border-radius: 10px; */
  width: 80%;
  margin: 0 10% 10px 10%;
  backdrop-filter: blur(103.05628967285156px) brightness(0.4);
  transform-origin: top;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  /* border: 3px solid #8e8e8e3b; */
  /* &::after {
    content: "";
    display: block;
    z-index: -1;
    position: absolute;
    height: 1000vh;
    top: 0;
    transform-origin: center;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-image: linear-gradient(to right, blue, black, black);
    animation: ${rotateGradient} linear 10s infinite forwards;
  } */

  @media ${DeviceSizes.mobile} {
    height: unset;
    border-radius: 30px;
    width: 92%;
    margin: 0 4% 10px 4%;
  }
`;

const CardButton = styled(Button)`
  && {
    border-radius: 50px;
    color: ${CommonColor.white};
    &.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
      border: 1px solid ${CommonColor.white} !important;
    }
  }
`;

const LandingComponentContainer = styled.div`
  height: 100vh;
  background-color: ${CommonColor.black};
  overflow: hidden;
  position: relative;
  @media ${DeviceSizes.mobile} {
    height: 80vh;
  }
`;
const rotation = keyframes`
0%{
  rotate: 0;
}
75%{
  rotate: 240deg;
}
100%{
  rotate: 360deg;
}
`;
const BackgroundImageContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 400vw;
  left: -118%;
  top: 23%;
  z-index: 0;
  filter: brightness(1);
  /* pointer-events: none; */
  & img {
    /* transform: rotateZ(-120deg); */
    /* animation-name: ${rotation}; */
    /* animation-iteration-count: infinite; */
    /* animation-duration: 60s; */
  }

  @media ${DeviceSizes.mobile} {
    left: 47%;
    top: 102%;

    height: initial;
    width: initial;
  }
`;
const RotateAnimation = keyframes`
0%{
transform: rotateZ(0);
}
25%{
transform: rotateZ(90deg);
}
50%{
transform: rotateZ(180deg);
}
75%{
transform: rotateZ(270deg);
}
100%{
transform: rotateZ(360deg);
}
`;

const noise = keyframes`
0%, 100% {
    background-position: 0 0;
}
10% {
    background-position: -5% -10%;
}
20% {
    background-position: -15% 5%;
}
30% {
    background-position: 7% -25%;
}
40% {
    background-position: 20% 25%;
}
50% {
    background-position: -25% 10%;
}

60% {
    background-position: 15% 5%;
}
70% {
    background-position: 0 15%;
}
80% {
    background-position: 25% 35%;
}
90% {
    background-position: -10% 10%;
}
`;
const BackgroundImageNoise = styled.div`
  width: ${(props) => props.size + "vw"};
  position: absolute;
  z-index: ${(props) => 7 - props.index};
  background: url(${Noise});
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  aspect-ratio: 1/1;
  transform-origin: center;
  opacity: 0.5;
  animation: ${noise} 1s infinite;
  transform: translateZ(0);
  pointer-events: none;
  @media ${DeviceSizes.mobile} {
    display: none;
    animation: none;
  }
`;
const LandingComponentTitleContainer = styled.div``;
const LandingPageComponnetTitle = styled.div`
  font-size: 6em;
  font-weight: 600;
  color: ${CommonColor.white};
  margin-top: 25vh;
  line-height: 1.2em;
  & > div:nth-child(1) {
    overflow: hidden;
    height: max-content;
  }
  & > div:nth-child(2) {
    color: ${CommonColor.secondary_color};
    display: flex;
    justify-content: flex-start;
    height: max-content;
  }
  @media ${DeviceSizes.mobile} {
    font-size: 15em;
    /* margin-top: 15vh; */
  }
`;
const Div = styled.div``;
const LandingComponentContentCotnainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  top: 0;
  left: 0;
  padding-left: 18%;
  @media ${DeviceSizes.mobile} {
    padding-left: 4%;
    padding-right: 4%;
    width: calc(100vw - 10%);
  }
`;
const LandingPageTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 80%;
  margin-top: 3%;
  @media ${DeviceSizes.mobile} {
    margin-top: 1%;
    width: 90%;
  }
`;
const ScrollIconContainer = styled.svg`
  margin-bottom: -24px;
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;
const LandingPageText = styled.div`
  color: ${CommonColor.white};
  margin-left: 10%;
  font-size: 1.2em;
  line-height: 150%;
  width: 70%;
  & > .line {
    margin-left: -1px;
  }
  @media ${DeviceSizes.mobile} {
    margin-left: 0;
    font-size: 14px;
    margin-top: 15px;
    width: 90%;
    font-size: ${CommonFont.xil};
    font-weight: 400;
  }
`;
const MobileContentContainer = styled.div`
  display: none;
  @media ${DeviceSizes.mobile} {
    display: block;
  }
`;
const TextContainer = styled.div`
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
  width: ${(props) => props.$width};
  color: ${(props) => props.$color};
  margin: ${(props) => props.$margin};
`;
const ReviewPageContainer = styled.div`
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  ); */
  background: black;
  height: 87vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-gap: 50px;
  padding: 15px;
  position: relative;
  @media ${DeviceSizes.mobile} {
    grid-gap: 25px;
    padding-left: 5%;
    padding-right: 5%;
  }
`;
const ReviewPageTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: 4 / span 7;
  grid-column: 2 / span 2;
  justify-content: space-between;
  @media ${DeviceSizes.mobile} {
    grid-row: auto;
    grid-column: span 12;
  }
`;
const ReviewPageTitle = styled.div`
  font-family: Montserrat;
  font-size: ${CommonFont.n};
  font-weight: 500;
  color: ${CommonColor.white};
  width: 100%;
  text-transform: uppercase;
  line-height: 120%;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xil};
  }
`;
const ReviewPageBackground = styled.img`
  align-self: flex-start;
  height: 100px;
  aspect-ratio: 1 / 1;
  position: absolute;
  bottom: 0;
  left: 0;
`;
const ReviewPageTextContainer = styled.div`
  flex-grow: 6;
  font-size: ${CommonFont.m};
  grid-row: 4 / span 7;
  grid-column: span 8;
  @media ${DeviceSizes.mobile} {
    grid-row: auto;
    grid-column: span 12;
  }
`;
const ReviewPageTitleLineContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 3%;
`;
const ReviewPageTextTitle = styled.div`
  & div {
    font-weight: 600;
    color: ${CommonColor.white};
    font-size: ${CommonFont.xs};
  }
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vil};
  }
`;
const ReviewPageReview = styled.div`
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  color: ${CommonColor.white};
  opacity: 0.75;
  font-size: ${CommonFont.xs};
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  user-select: none;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.l};
  }
`;
const TitleLine = styled.div`
  height: 2px;
  width: 20px;
  background-color: ${CommonColor.white};
  margin-right: 8px;
  margin-top: auto;
  margin-bottom: auto;
`;
const Span = styled.span`
  transition: transform 0.5s ease-in-out;
`;

const ReviewPageWrapperWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const ReviewPageReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & .react-multi-carousel-dot-list {
    flex-direction: column;
    width: max-content;
    left: unset;
    bottom: unset;
    right: 0;
    top: 0;
  }
  & li {
    margin-right: 1px;
  }
  & li > div {
    width: calc(100% - 20px);
  }

  @media ${DeviceSizes.abovetablet} {
  }
  @media ${DeviceSizes.mobile} {
    & .react-multi-carousel-dot-list {
      flex-direction: row;
      width: 100%;
      top: unset;
      right: unset;
      bottom: 0;
      left: 0;
      justify-content: flex-start;
      & div {
        margin-right: 2px;
        height: 5px;
      }
      & ul:first-child {
        height: 500px;
      }
    }

    & .react-multi-carousel-list.carousel-container {
      height: max-content;
      padding-bottom: 20px;
      align-items: self-start;
    }
  }
`;
const ReviewPageReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  cursor: none;
`;

const Cursor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: ${CommonColor.secondary_color};
  height: 60px;
  width: 60px;
  border-radius: 50%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 10000000000000;
  @media (hover: none) {
    display: none;
  }
`;
const BackgroundLandingBottom = styled.div`
  margin-left: -20%;
  height: 19%;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 1) 20%);
  filter: blur(24px);
  position: absolute;
  overflow: hidden;
  transform: scale(1.22);
  @media ${DeviceSizes.mobile} {
    margin-top: 16%;
  }
`;
const CircleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: 30px;
  @media ${DeviceSizes.mobile} {
    margin-left: 0;
    margin-top: 20px;
  }
`;
const CircleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  @media ${DeviceSizes.mobile} {
    flex-direction: row;
    width: 70px;
  }
`;
const CircleIndividualContainer = styled.div`
  width: 10px;
  height: ${(props) => (props.$isVisible ? "25px" : "12.5px")};
  cursor: pointer;
  margin-bottom: 5px;
  transition: height 0.5s ease-in-out;
  @media ${DeviceSizes.mobile} {
    height: 10px;
    width: ${(props) => (props.$isVisible ? "25px" : "12.5px")};
    transition: width 0.5s ease-in-out;
  }
`;
const Circle = styled.div`
  width: 50%;
  height: ${(props) => (props.$isVisible ? "25px" : "12.5px")};
  border-radius: 10px;
  /* border: 1px solid ${CommonColor.grey}; */
  margin-left: auto;
  margin-right: auto;
  background: ${(props) =>
    props.$isVisible ? CommonColor.secondary_color : CommonColor.grey};

  transition: all 0.5s ease-in-out;
  cursor: pointer;
  @media ${DeviceSizes.mobile} {
    width: ${(props) => (props.$isVisible ? "25px" : "12.5px")};
    height: 4px;
    margin-bottom: 0;
    margin-right: 5px;
  }
`;
const RippleCircularButton = styled.button`
  height: 50px;
  width: 120px;
  border-radius: 50px;
  border: 1px solid ${CommonColor.white};
  color: ${CommonColor.primary_color};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: ${CommonFont.n};
  font-weight: 600;
  overflow: hidden;
  background-color: ${CommonColor.white};
  cursor: pointer;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vil};
    margin-top: 15px;
    height: 40px;
    width: 90px;
    font-weight: 700;
    letter-spacing: 0.6px;
  }
`;
const ButtonContainerRipple = styled.div`
  margin-top: 20px;
`;
const RippleCircularButtonBg = styled.span`
  height: 300px;
  width: 300px;
  border-radius: 50%;
  position: absolute;
  background: ${CommonColor.secondary_color};
  /* top: 0;
  left: 0; */
  /* z-index: -1; */
`;
const MuiButtonGetInTouch = styled(Button)`
  && {
    background-color: ${CommonColor.white};
    border-radius: 50px;
    color: ${CommonColor.primary_color};
    padding: 15px 45px;
    &:hover {
      background-color: ${CommonColor.white};
    }
  }
`;
const LottieWrapper = styled.div`
  position: relative;
  /* left: -10px; */
`;

const ReviewPageTail = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100px;
  width: 100vw;
`;
const ReviewDividerContainer = styled.div`
  grid-column: 2 / span 10;
  grid-row: 3 / span 1;
  @media ${DeviceSizes.mobile} {
    grid-column: span 12;
    grid-row: unset;
  }
`;
const CardNumberContainer = styled.div`
  width: 59px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 1px solid ${CommonColor.secondary_color};
  color: ${CommonColor.secondary_color};
  font-size: ${CommonFont.m};
  font-weight: 700;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${CommonColor.light_secondary};
  @media ${DeviceSizes.mobile} {
    width: 30px;
    font-size: ${CommonFont.vil};
    font-weight: 400;
  }
`;
const CardTitle = styled.div`
  color: ${CommonColor.white};
  font-size: ${CommonFont.semiLarge};
  font-weight: 700;
  line-height: 150%;
  margin-top: 20px;
  text-transform: capitalize;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    font-size: ${CommonFont.xxxl};
    margin-top: 20px;
  }
`;

const CardContentTitle = styled.div`
  color: ${CommonColor.grey};
  font-size: ${CommonFont.s};
  font-weight: 500;
  line-height: 140%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    font-size: ${CommonFont.vl};
    font-weight: 400;
  }
`;

const CardDetailIndex = styled(CardContentTitle)`
  color: ${CommonColor.white};
  height: 100%;
  @media ${DeviceSizes.mobile} {
    width: max-content;
  }
`;
const CardDetailTextContainer = styled.div`
  margin-left: 20px;
  height: 100%;
  @media ${DeviceSizes.mobile} {
    margin-left: 10px;
  }
`;
const CardDetailTextTitle = styled(CardContentTitle)`
  color: ${CommonColor.white};
  font-weight: 600;
  @media ${DeviceSizes.mobile} {
    font-weight: 800;
    font-size: ${CommonFont.vl};
  }
`;
const CardDetailText = styled(CardContentTitle)`
  color: ${CommonColor.white};
  font-size: ${CommonFont.xs};
  font-weight: 500;
  margin-top: 10px;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vl};
    line-height: 150%;
    margin-top: 5px;
  }
`;

const CardDetailTextBullet = styled.div`
  margin-top: 4px;
`;

export {
  CardDetailTextBullet,
  CardDetailText,
  CardDetailTextTitle,
  CardDetailTextContainer,
  CardDetailIndex,
  CardContentTitle,
  CardTitle,
  CardNumberContainer,
  CircleIndividualContainer,
  ReviewDividerContainer,
  ReviewPageTail,
  LottieWrapper,
  MuiButtonGetInTouch,
  RippleCircularButtonBg,
  ButtonContainerRipple,
  RippleCircularButton,
  CircleWrapper,
  Circle,
  CircleContainer,
  BackgroundLandingBottom,
  Cursor,
  ReviewPageWrapperWrapper,
  ReviewPageReviewContainer,
  ReviewPageReviewWrapper,
  Span,
  ReviewPageTitleLineContainer,
  TitleLine,
  ReviewPageReview,
  ReviewPageTextTitle,
  ReviewPageTextContainer,
  ReviewPageBackground,
  ReviewPageTitle,
  ReviewPageTitleContainer,
  ReviewPageContainer,
  TextContainer,
  MobileContentContainer,
  LandingPageText,
  ScrollIconContainer,
  LandingPageTextContainer,
  TitleWrapperContainer,
  LandingComponentContentCotnainer,
  Div,
  LandingPageComponnetTitle,
  LandingComponentTitleContainer,
  BackgroundImageNoise,
  BackgroundImageContainer,
  LandingComponentContainer,
  CardButton,
  CardContainer,
  CardMainContainer,
  CardWrapper,
  DividerLine,
  ServicesTitle,
  ServiceWrapper,
  TitleContainer,
  WETitle,
  VerticalLine,
  DotIcon,
  HomeContainer,
  HomeFirstContainer,
  Title,
};
