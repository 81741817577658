import React from "react";
import {
  PrivacyContainer,
  PrivacyHeader,
  PrivacyPara,
} from "../../style/privacy.style";
import { Div } from "../../style/common.style";
import { onChangeNav } from "../../service/Redux/UserStore";
import { path } from "../../utils/Path";
import { useDispatch } from "react-redux";
import useScreenSize from "../../hooks/WindowSize";

export default function TermsSectionOne() {
  const dispatch = useDispatch();
  const windowWidth = useScreenSize().width;
  return (
    <PrivacyContainer>
      <PrivacyHeader id="privacy-header">
        Terms and {windowWidth < 600 ? <br /> : ""}
        Conditions
      </PrivacyHeader>
      <Div id="privacy-text">
        <PrivacyPara>
          During the Company’s Recruitment Process, professionals can choose to
          be contacted via text regarding their application process for
          employment. This may continue when the professionals are hired till
          the end of their employment with the Company. The Company may also use
          contact information to share other job opportunities that may be
          suitable for professionals. Individuals providing their contact
          information with the company consent to receive text messages
          regarding their application, employment, or other job opportunities.
        </PrivacyPara>
        <PrivacyPara>
          During the Company’s Recruitment Process, professionals can choose to
          be contacted via text regarding their application process for
          employment. This may continue when the professionals are hired till
          the end of their employment with the Company. The Company may also use
          contact information to share other job opportunities that may be
          suitable for professionals. Individuals providing their contact
          information with the company consent to receive text messages
          regarding their application, employment, or other job opportunities.
        </PrivacyPara>
        <PrivacyPara>
          Issues regarding messaging programs can be addressed directly by
          replying with the keyword “HELP” for assistance, or you can
          directly&nbsp;
          <span
            className="link"
            onClick={() => dispatch(onChangeNav(path.contact))}
          >
            Contact Us
          </span>
        </PrivacyPara>
        <PrivacyPara>
          Text messages can be canceled by simply replying with the keyword
          “STOP”. A confirmation text message will be sent back notifying the
          individual regarding the cancellation of the subscription to text
          messages. Text messages can be resumed by signing up again.
        </PrivacyPara>
        <PrivacyPara>
          Message and data rates may apply for any messages sent and/or
          received. Most messages received may be as part of the interview and
          hiring process and message frequency varies. Contact your wireless
          provider for any questions about the text plan or data plan. Carriers
          are not liable for delayed or undelivered messages.
        </PrivacyPara>
        <PrivacyPara>
          Please read our&nbsp;
          <span
            className="link"
            onClick={() => dispatch(onChangeNav(path.privacy_policy))}
          >
            Privacy Policy
          </span>
          &nbsp;for any questions regarding privacy.
        </PrivacyPara>
      </Div>
    </PrivacyContainer>
  );
}
