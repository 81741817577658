import React, { useCallback, useEffect, useRef } from "react";
import {
  ParaText,
  ParaTitle,
  RecruitementParaContainer,
} from "../../style/recruitment.style";
import { FlexContainer } from "../../style/common.style";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import useScreenSize from "../../hooks/WindowSize";
gsap.registerPlugin(ScrollTrigger);

export default function RecruitementPara() {
  const textRef = useRef(null);
  const width = useScreenSize().width;
  const paraContainer = useRef(null);

  const animateText = useCallback(() => {
    const text = new SplitType(textRef.current, { types: "words" });
    let tl;
    if (width > 600) {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: textRef.current,
          start: `center center`,
          end: `bottom top`,
          scrub: true,
        },
      });
    } else {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: textRef.current,
          start: `top bottom`,
          end: `center center`,
          scrub: true,
        },
      });
    }

    text.words.forEach((word) => {
      if (width > 600) {
        tl.from(word, { opacity: 0.5 });
      } else {
        tl.from(word, { opacity: 1 });
      }
    });

    return () => {
      tl.kill();
    };
  }, []);

  useEffect(() => {
    const cleanup = animateText();
    if (paraContainer.current === null) return;
    if (width > 600) {
      ScrollTrigger.create({
        trigger: paraContainer.current,
        start: `center center`,
        end: `bottom+=100 top`,
        pin: true,
        pinSpacing: true,
        id: "recrutiement-para",
        invalidateOnRefresh: true,
        anticipatePin: 1,
        markers: false,
      });
    }

    return () => cleanup();
  }, [animateText]);

  return (
    <RecruitementParaContainer ref={paraContainer} id="recrutiement-para">
      <FlexContainer
        id="recruitement-p-container"
        width={width < 600 ? "calc(100% - 20px)" : "100%"}
        verticalAlign="flex-start"
        flexDirection={width < 600 ? "column" : ""}
        margin={width < 600 ? "10px 0% 0 20px" : "10px 10% 0 25%"}
      >
        <ParaTitle>What we do</ParaTitle>
        <ParaText className="recruitement-p-text" ref={textRef}>
          <div>
            Welcome to our Digital Solutions offerings! With a dedicated group
            of highly skilled professionals, we bring years of experience and
            expertise to the table. Combining strategic thinking, innovative
            design, and the latest technologies, we strive to develop pioneering
            solutions that stand out in today's competitive landscape. Our goal
            is to create projects with visually stunning design, seamless
            usability, and robust functionality to provide users with an
            exceptional experience.
            <br />
            <br />
            Partner with us for reliable Digital Solutions that drive results
            and elevate your digital presence. Contact us today to learn more
            about how we can help your business thrive in the digital realm.
          </div>
        </ParaText>
      </FlexContainer>
    </RecruitementParaContainer>
  );
}
