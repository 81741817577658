import gsap from "gsap";
import React from "react";
import { FlexContainer } from "../../style/common.style";
import { TitleLine } from "../../style/home.style";
import { BAE1Img, BAE2Img } from "../../style/it.style";
import {
  CardImgContainerRecMobile,
  LinearGradientBg,
  RecruitementFeaturSectionContainer,
  RecruitementFeaturSectionContainerMobile,
  RecruitementFeatureCardContent,
  RecruitementFeatureCardContentBig,
  RecruitementFeatureCardContentContainer,
  RecruitementFeatureCardList,
  RecruitementFeatureCardTitle,
  RecruitementFeatureContainerBig,
  RecruitementFeatureContainerSmall,
  RecruitementFeatureTitle,
  RecruitmentFeatureBgWrapper,
  RecruitmentFeatureCard,
  RecruitmentFeatureCardBig,
  RecruitmentFeatureNumber,
} from "../../style/recruitment.style";
import { CommonColor } from "../../utils/Color";
import AnimatedNumber from "react-animated-number";

export default function RecruitementGrid() {
  function movingCursor(event) {
    const cards = document.querySelectorAll(".card-recruitement");
    cards.forEach((card, index) => {
      gsap.to(card, {
        "--mouse-x": event.clientX - card.getBoundingClientRect().left + "px",
        "--mouse-y": event.clientY - card.getBoundingClientRect().top + "px",
      });
    });
  }
  function customFormatValue(value, isAppend) {
    if (isAppend) {
      return value.toString() + "+";
    }
    return value.toString();
  }
  return (
    <React.Fragment>
      <RecruitementFeaturSectionContainer onMouseMove={(e) => movingCursor(e)}>
        <RecruitementFeatureContainerSmall>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>
            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              />

              <RecruitementFeatureTitle>
                Buisnesses Prexeled
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">5</span> */}
                <AnimatedNumber
                  component="text"
                  value={5}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Public Sector Contract Awards
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">100</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={100}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </RecruitementFeatureContainerSmall>
        <RecruitementFeatureContainerBig>
          <RecruitmentFeatureCardBig className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContentBig>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "rgba(138, 209, 255, 0.53)" }}
              />

              <RecruitementFeatureCardContentContainer>
                <RecruitementFeatureCardTitle>
                  Business Assistance
                </RecruitementFeatureCardTitle>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Accounting & Finance Support
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    IT/HR Support
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Professional Customer Support
                  </RecruitementFeatureCardList>
                </FlexContainer>
              </RecruitementFeatureCardContentContainer>
              <RecruitmentFeatureBgWrapper>
                <BAE1Img />
              </RecruitmentFeatureBgWrapper>
            </RecruitementFeatureCardContentBig>
          </RecruitmentFeatureCardBig>
          <RecruitmentFeatureCardBig className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContentBig>
              <RecruitementFeatureCardContentContainer>
                <RecruitementFeatureCardTitle>
                  Business Expansion
                </RecruitementFeatureCardTitle>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Business Expansion Consulting
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Business Process Optimisation
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Market Research and Lead Generation
                  </RecruitementFeatureCardList>
                </FlexContainer>
              </RecruitementFeatureCardContentContainer>
              <RecruitmentFeatureBgWrapper>
                <BAE2Img />
              </RecruitmentFeatureBgWrapper>
            </RecruitementFeatureCardContentBig>
          </RecruitmentFeatureCardBig>
        </RecruitementFeatureContainerBig>
        <RecruitementFeatureContainerSmall>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "rgba(138, 209, 255, 0.53)" }}
              />

              <RecruitementFeatureTitle>
                Proposals Crafted for master contracts
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* 350+ */}
                <AnimatedNumber
                  component="text"
                  value={350}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#76401D" }}
              />

              <RecruitementFeatureTitle>
                Processes Streamlined
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">10</span> */}
                <AnimatedNumber
                  component="text"
                  value={10}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </RecruitementFeatureContainerSmall>
      </RecruitementFeaturSectionContainer>
      <RecruitementFeaturSectionContainerMobile>
        <FlexContainer
          flexDirection="column"
          height="max-content"
          padding="30px"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0 20px 22px 20px"
          width="calc(100% - 40px)"
          bg="#191919"
          borderRadius="20px"
        >
          <CardImgContainerRecMobile>
            <BAE1Img />
          </CardImgContainerRecMobile>
          <RecruitementFeatureCardContentContainer>
            <RecruitementFeatureCardTitle>
              Business Assistance
            </RecruitementFeatureCardTitle>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Accounting & Finance Support
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                IT/HR Support
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Professional Customer Support
              </RecruitementFeatureCardList>
            </FlexContainer>
          </RecruitementFeatureCardContentContainer>
        </FlexContainer>

        <FlexContainer
          flexDirection="column"
          height="max-content"
          padding="30px"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0 20px 22px 20px"
          width="calc(100% - 40px)"
          bg="#191919"
          borderRadius="20px"
        >
          <CardImgContainerRecMobile>
            <BAE2Img />
          </CardImgContainerRecMobile>
          <RecruitementFeatureCardContentContainer>
            <RecruitementFeatureCardTitle>
              Business Expansion
            </RecruitementFeatureCardTitle>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Business Expansion Consulting
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Business Process Optimisation
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Market Research and Lead Generation
              </RecruitementFeatureCardList>
            </FlexContainer>
          </RecruitementFeatureCardContentContainer>
        </FlexContainer>
        <FlexContainer
          flexWrap="wrap"
          height="max-content"
          jc="space-between"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0px 20px 0 20px"
          width="calc(100% - 40px)"
          gap="20px 10px"
        >
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Buisnesses Prexeled
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">5</span> */}
                <AnimatedNumber
                  component="text"
                  value={5}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Public Sector Contract Awards
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">100</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={100}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>{" "}
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Proposals Crafted For Master Contracts
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">350+</span> */}
                <AnimatedNumber
                  component="text"
                  value={350}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>{" "}
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Processes Streamlined
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">10</span> */}
                <AnimatedNumber
                  component="text"
                  value={10}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </FlexContainer>
      </RecruitementFeaturSectionContainerMobile>
    </React.Fragment>
  );
}
