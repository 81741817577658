import React, { useEffect } from "react";
import Header from "../commonComponent/Header";
import gsap from "gsap";
import { endLoader } from "../../utils/Functions";
import AboutUsPage from "./AboutUsPage";
import { ContactContainer } from "../../style/contact.style";
import Footer from "../commonComponent/Footer";
import { ReviewPageTail } from "../../style/home.style";
import SplitType from "split-type";
import StickySocialIcon from "../commonComponent/StickySocialIcon";
import { ScrollTrigger } from "gsap/all";
import useScreenSize from "../../hooks/WindowSize";
import { ScrollRestoration } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onTogglePreLoader } from "../../service/Redux/UserStore";

gsap.registerPlugin(ScrollTrigger);

function About() {
  const dispatch = useDispatch();
  const windowWidth = useScreenSize().width;
  useEffect(() => {
    ScrollTrigger.refresh();
    const timeline = gsap.timeline();
    endLoader(timeline);
    dispatch(onTogglePreLoader(false));
    const navbar = document.querySelector(".header");
    const animatedTextWe = document.querySelector("#about-us");
    const charWeText = new SplitType(animatedTextWe, { types: "chars" });
    const animatedText = document.querySelector("#about-us-text");
    const charAnimatedText = new SplitType(animatedText, { types: "lines" });
    // const lottieLoader = document.querySelector("#lottie-wrapper");
    const stickySocialIcons = document.querySelectorAll(
      ".social-icon-container.cursor-circle-blast"
    );
    const lottieLoader = document.querySelector("#lottie-wrapper");
    timeline.from(
      navbar,
      {
        y: -navbar.clientHeight,
        opacity: 0.4,
        duration: 1,
        delay: 0.3,
      },
      "-=80%"
    );
    timeline.to(navbar, {
      y: 0,
      opacity: 1,
    });
    timeline.from(
      charWeText.chars,
      {
        opacity: 0,
        y: 60,
      },
      "-=150%"
    );
    timeline.from(charAnimatedText.lines, {
      opacity: 0,
      y: 30,
      stagger: 0.1,
      ease: "power1.out",
      duration: 0.5,
    });
    stickySocialIcons.forEach((stickySocialIcon, index) => {
      timeline.from(
        stickySocialIcon,
        {
          opacity: 0,
          scale: 0.1,
          duration: 0.5,
          ease: "power1.out",
        },
        "-=90%"
      );
    });
    timeline.from(lottieLoader, {
      opacity: 0,
    });
    timeline.to(lottieLoader, {
      opacity: 1,
    });
    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);
  return (
    <React.Fragment>
      <ContactContainer>
        {/*<ScrollRestoration />*/}

        <Header />
        <AboutUsPage />
        <StickySocialIcon />
        <ReviewPageTail style={{ position: "absolute", bottom: -100 }} />
      </ContactContainer>
      <Footer />
    </React.Fragment>
  );
}

export default About;
