const CommonString = {
  screen_padding: "80px",
  screen_top_margin: "80px",
  main_screen_top_margin: "150px",
  copy_right_text: "@prexelllc.com",
  copy_policy: "Cookie Policy",
  privacy_policy: "Privacy Policy",
  term_condition: "Terms & Conditions ",
  prexel_address:
    "211B to 214, Baroda Commerce House, Near Nilamber Circle Gotri-Bhayli Road, Vadodara GJ, 390021, India",
  us_address:
    "1309 Coffeen Avenue, STE 1200, Sheridan, WY, 82801, United States",
  phone_number: " +1 (307) 363-7877",
  phone: "Phone",
  us: "US",
  india: "India",
  drop_us_line: "Drop Us a Line",
  lets: "Let’s",
  collaborate: "Collaborate",
  home: "Home",
  about: "About",
  service: "Service",
  contact: "Contact",
  privacy_notice: "Privacy Notice",
  us_address_link: "https://goo.gl/maps/jq25PWavc2dDJVjy8",
  india_address_link: "https://goo.gl/maps/iZF7k2DDHu4N4Vk66",
  phone_link: "tel:+1 (307) 363-7877",
  home_landing_page_desc:
    "Prexel is a consulting firm Promoting, Expanding, and Elevating people and \nbusinesses across the world in the digital and real world with our high-\nquality IT, Recruitment, Business Expansion services.",
  get_in_touch: "Get in Touch",
  services: "Our Wings",
  scroll: "Scroll",
  explore: "Explore",
  expand: "expand",
  we: "we",
  header_logo: "headerLogo",
};
export { CommonString };
