import React, { useCallback, useEffect, useRef } from "react";
import {
  ParaText,
  ParaTitle,
  RecruitementParaContainer,
} from "../../style/recruitment.style";
import { FlexContainer } from "../../style/common.style";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import useScreenSize from "../../hooks/WindowSize";
gsap.registerPlugin(ScrollTrigger);

export default function RecruitementPara() {
  const textRef = useRef(null);
  const width = useScreenSize().width;
  const paraContainer = useRef(null);

  const animateText = useCallback(() => {
    const text = new SplitType(textRef.current, { types: "words" });
    let tl;
    if (width > 600) {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: textRef.current,
          start: `center center`,
          end: `bottom top`,
          scrub: true,
        },
      });
    } else {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: textRef.current,
          start: `top bottom`,
          end: `center center`,
          scrub: true,
        },
      });
    }

    text.words.forEach((word) => {
      if (width > 600) {
        tl.from(word, { opacity: 0.5 });
      } else {
        tl.from(word, { opacity: 1 });
      }
    });

    return () => {
      tl.kill();
    };
  }, []);

  useEffect(() => {
    const cleanup = animateText();
    if (paraContainer.current === null) return;
    if (width > 600) {
      ScrollTrigger.create({
        trigger: paraContainer.current,
        start: `center center`,
        end: `bottom+=100 top`,
        pin: true,
        pinSpacing: true,
        id: "recrutiement-para",
        invalidateOnRefresh: true,
        anticipatePin: 1,
        markers: false,
      });
    }

    return () => cleanup();
  }, [animateText]);

  return (
    <RecruitementParaContainer ref={paraContainer} id="recrutiement-para">
      <FlexContainer
        id="recruitement-p-container"
        width={width < 600 ? "calc(100% - 20px)" : "100%"}
        verticalAlign="flex-start"
        flexDirection={width < 600 ? "column" : ""}
        margin={width < 600 ? "10px 0% 0 20px" : "10px 10% 0 25%"}
      >
        <ParaTitle>What we do</ParaTitle>
        <ParaText className="recruitement-p-text" ref={textRef}>
          <div>
            Our Business Support and Growth team consists of highly skilled
            professionals experienced in Accounting, HR, IT support, Sales,
            Research, Analysis, Content Creation, and Processes Improvement.
            We're proud to say our team is known for helping businesses in
            different industries grow, expand globally, and significantly
            increase their revenues.
            <br />
            <br />
            Over the years, we've assisted more than five businesses in refining
            their processes and winning new contracts in both private and public
            sectors. Our team combines thorough analysis, strategic planning,
            and innovative ideas to provide cutting-edge consulting services
            that consistently enhance businesses.
          </div>
        </ParaText>
      </FlexContainer>
    </RecruitementParaContainer>
  );
}
