import styled from "styled-components";
import { CommonColor } from "../utils/Color";
import { CommonFont } from "../utils/Font";
import { DeviceSizes } from "./common.style";

const PrivacyContainer = styled.div`
  margin-left: 5vw;
  margin-right: 5vw;
  color: ${CommonColor.black};
  height: max-content;
  color: ${CommonColor.white};
  padding-bottom: 60px;
  @media ${DeviceSizes.belowtablet} {
    margin-left: 8vw;
    margin-right: 8vw;
  }
`;

const PrivacyHeader = styled.h1`
  color: ${CommonColor.secondary_color};
  font-size: ${CommonFont.xxl};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 20vh;
  margin: 0;
  overflow: hidden;
  @media ${DeviceSizes.belowtablet} {
    padding-top: 12vh;
  }
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xxxl};
  }
`;

const PrivacyPara = styled.p`
  font-size: ${CommonFont.s};
  line-height: 150%;
  & span.link {
    text-decoration: underline;
    cursor: pointer;
  }
  @media ${DeviceSizes.belowtablet} {
    font-size: ${CommonFont.mSemiB};
  }
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vil};
  }
`;

const PrivacyBigText = styled.div`
  font-size: ${CommonFont.mSemiB};
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 58.5px */
  letter-spacing: 0.488px;
  color: ${CommonColor.white};
  margin-top: 50px;

  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xil};
  }
`;

const PrivacyScreenContainer = styled.div`
  background-color: black;
  position: relative;
  z-index: 1;
`;
export {
  PrivacyContainer,
  PrivacyHeader,
  PrivacyPara,
  PrivacyBigText,
  PrivacyScreenContainer,
};
