import React from "react";
import { FlexContainer } from "../../style/common.style";
import { TitleLine } from "../../style/home.style";
import {
  CardImgContainerRecMobile,
  LinearGradientBg,
  MovingBgRecruitement,
  RecruitementExecutionServiceImg,
  RecruitementFeaturSectionContainer,
  RecruitementFeaturSectionContainerMobile,
  RecruitementFeatureCardContent,
  RecruitementFeatureCardContentBig,
  RecruitementFeatureCardContentContainer,
  RecruitementFeatureCardList,
  RecruitementFeatureCardTitle,
  RecruitementFeatureContainerBig,
  RecruitementFeatureContainerSmall,
  RecruitementFeatureImg,
  RecruitementFeatureTitle,
  RecruitementMobSvg,
  RecruitmentFeatureBgWrapper,
  RecruitmentFeatureCard,
  RecruitmentFeatureCardBig,
  RecruitmentFeatureNumber,
} from "../../style/recruitment.style";
import { CommonColor } from "../../utils/Color";
import gsap from "gsap";
import { CommonImages } from "../../utils/CommonImages";
import AnimatedNumber from "react-animated-number";

export default function RecruitementGrid() {
  function movingCursor(event) {
    const cards = document.querySelectorAll(".card-recruitement");
    cards.forEach((card, index) => {
      gsap.to(card, {
        "--mouse-x": event.clientX - card.getBoundingClientRect().left + "px",
        "--mouse-y": event.clientY - card.getBoundingClientRect().top + "px",
      });
    });
  }
  function customFormatValue(value, isAppend) {
    if (isAppend) {
      return value.toString() + "+";
    }
    return value.toString();
  }
  return (
    <React.Fragment>
      <RecruitementFeaturSectionContainer
        onMouseEnter={(e) => movingCursor(e)}
        onMouseMove={(e) => movingCursor(e)}
      >
        <RecruitementFeatureContainerSmall>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>
            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              />

              <RecruitementFeatureTitle>
                Public Sector Clients
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">155</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={155}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>
            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />
              <RecruitementFeatureTitle>Talent Placed</RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">300</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={300}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </RecruitementFeatureContainerSmall>
        <RecruitementFeatureContainerBig>
          <RecruitmentFeatureCardBig className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContentBig>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "rgba(138, 209, 255, 0.53)" }}
              />

              <RecruitementFeatureCardContentContainer>
                <RecruitementFeatureCardTitle>
                  Talent Acquisition
                </RecruitementFeatureCardTitle>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Permanent Talent Acquisition
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Contractual–to–Perm Talent Acquisition
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Contractual Talent Acquisition
                  </RecruitementFeatureCardList>
                </FlexContainer>
              </RecruitementFeatureCardContentContainer>
              <RecruitmentFeatureBgWrapper>
                <RecruitementFeatureImg />
              </RecruitmentFeatureBgWrapper>
            </RecruitementFeatureCardContentBig>
          </RecruitmentFeatureCardBig>
          <RecruitmentFeatureCardBig className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContentBig>
              <RecruitementFeatureCardContentContainer>
                <RecruitementFeatureCardTitle>
                  Recruitment Extension Services
                </RecruitementFeatureCardTitle>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Permanent Talent Acquisition
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Contractual–to–Perm Talent Acquisition
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Contractual Talent Acquisition
                  </RecruitementFeatureCardList>
                </FlexContainer>
              </RecruitementFeatureCardContentContainer>
              <RecruitmentFeatureBgWrapper>
                <RecruitementExecutionServiceImg />
              </RecruitmentFeatureBgWrapper>
            </RecruitementFeatureCardContentBig>
          </RecruitmentFeatureCardBig>
        </RecruitementFeatureContainerBig>
        <RecruitementFeatureContainerSmall>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "rgba(138, 209, 255, 0.53)" }}
              />

              <RecruitementFeatureTitle>
                Private Sector Clients
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">300</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={300}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#76401D" }}
              />

              <RecruitementFeatureTitle>
                Part of Inception Team
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">05</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={5}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </RecruitementFeatureContainerSmall>
      </RecruitementFeaturSectionContainer>
      <RecruitementFeaturSectionContainerMobile>
        <FlexContainer
          flexDirection="column"
          height="max-content"
          padding="30px"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0 20px 22px 20px"
          width="calc(100% - 40px)"
          bg="#191919"
          borderRadius="20px"
        >
          <CardImgContainerRecMobile>
            <RecruitementMobSvg src={CommonImages.TalentAquisition} alt="" />
          </CardImgContainerRecMobile>
          <RecruitementFeatureCardContentContainer>
            <RecruitementFeatureCardTitle>
              Talent Acquisition
            </RecruitementFeatureCardTitle>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Permanent Talent Acquisition
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Contractual–to–Perm Talent Acquisition
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Contractual Talent Acquisition
              </RecruitementFeatureCardList>
            </FlexContainer>
          </RecruitementFeatureCardContentContainer>
        </FlexContainer>

        <FlexContainer
          flexDirection="column"
          height="max-content"
          padding="30px"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0 20px 20px 20px"
          width="calc(100% - 40px)"
          bg="#191919"
          borderRadius="20px"
        >
          <CardImgContainerRecMobile>
            <RecruitementMobSvg
              src={CommonImages.RecruitementExecutionServiceImgMob}
              alt=""
            />
          </CardImgContainerRecMobile>
          <RecruitementFeatureCardContentContainer>
            <RecruitementFeatureCardTitle>
              Recruitment Extension Services
            </RecruitementFeatureCardTitle>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Permanent Talent Acquisition
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Contractual–to–Perm Talent Acquisition
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Contractual Talent Acquisition
              </RecruitementFeatureCardList>
            </FlexContainer>
          </RecruitementFeatureCardContentContainer>
        </FlexContainer>
        <FlexContainer
          flexWrap="wrap"
          height="max-content"
          jc="space-between"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0px 20px 0 20px"
          width="calc(100% - 40px)"
          gap="20px 10px"
        >
          <RecruitmentFeatureCard xclassName="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Public Sector Clients
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">155</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={155}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Public Sector Clients
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">120</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={120}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>{" "}
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>Talent Placed</RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">300</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={300}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>{" "}
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Part of Inception Team
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">05</span> */}
                <AnimatedNumber
                  component="text"
                  value={5}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </FlexContainer>
      </RecruitementFeaturSectionContainerMobile>
    </React.Fragment>
  );
}
