import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { onChangeNav } from "../service/Redux/UserStore";
import AnimatedRoutes from "./AnimatedRoutes";

export default function RoutesComponent() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onChangeNav(window.location.pathname));
  }, []);

  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
}
