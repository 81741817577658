import { Icon } from "@iconify/react";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import arrowUpRightIcon from "../../assets/images/arrowUpRightIcon.svg";
import useScreenSize from "../../hooks/WindowSize";
import { onChangeNav } from "../../service/Redux/UserStore";
import { Div } from "../../style/common.style";
import {
  BottomFooterContainer,
  ContactTitle,
  ContactTitleContainer,
  FooterAnchorTag,
  FooterContainer,
  FooterLinkContainer,
  FooterMainContainer,
  FooterText,
  FooterTextWithIcon,
  FooterTopWrapper,
  GridContainer,
  LetsCollaborateText,
  LetsCollaborateTextContainer,
  LetsCollaborateTextIcon,
  LetsCollaborateTextIconContainer,
  PageTopScroll,
  SocialIconContainer,
  SocialIconSingleContainer,
} from "../../style/footer.style";
import { CommonString } from "../../utils/CommonString";
import { path } from "../../utils/Path";
import "./Footer.css";
gsap.registerPlugin(ScrollToPlugin);

export default function Footer() {
  const windowWidth = useScreenSize().width;
  const dispatch = useDispatch();
  const location = useLocation();
  function arrowAnimate(event) {
    const boxmeasurenment = document
      .querySelector("#footer-arrow")
      .getBoundingClientRect();
    const x = event.clientX - boxmeasurenment.left - boxmeasurenment.width / 2;
    const y = event.clientY - boxmeasurenment.top - boxmeasurenment.height / 2;
    gsap.to(event.currentTarget, {
      y: y * 0.3,
      x: x * 0.3,
      scale: 0.9,
    });
    gsap.to(".cursor", {});
  }
  function arrowReset(event) {
    gsap.to(event.currentTarget, {
      x: 0,
      y: 0,
      scale: 1,
    });
  }
  function forwardToContact() {
    if (location.pathname === path.contact) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }
    dispatch(onChangeNav(path.contact));
  }
  return (
    <FooterMainContainer>
      <FooterContainer>
        <FooterTopWrapper>
          <ContactTitleContainer>
            <ContactTitle>{CommonString.drop_us_line}</ContactTitle>
            <PageTopScroll
              onClick={async () =>
                gsap.to(window, {
                  duration: 2,
                  scrollTo: 0,
                  ease: "power4.out",
                })
              }
            >
              back to top
            </PageTopScroll>
          </ContactTitleContainer>
          <LetsCollaborateTextContainer>
            <FooterTextWithIcon>
              <LetsCollaborateText>{CommonString.lets}</LetsCollaborateText>
              <LetsCollaborateTextIconContainer
                onMouseMove={(e) => arrowAnimate(e)}
                onMouseLeave={(e) => arrowReset(e)}
                onClick={() => forwardToContact()}
                id="footer-arrow"
              >
                <LetsCollaborateTextIcon src={arrowUpRightIcon} />
              </LetsCollaborateTextIconContainer>
            </FooterTextWithIcon>
            <LetsCollaborateText>
              {CommonString.collaborate}
            </LetsCollaborateText>
          </LetsCollaborateTextContainer>
          {/* <FooterBackgroundImg src={footerbackgroundArrowImg} /> */}
        </FooterTopWrapper>
        <GridContainer>
          {/* <Div container spacing={{ md: 4, xs: 2 }}> */}
          <Div
            style={{
              width: windowWidth < 600 ? "100%" : "25%",
              marginTop: windowWidth < 600 ? "6%" : "0",
            }}
          >
            <FooterTextWithIcon>
              <FooterText is_bold={true.toString()}>
                {CommonString.us}
              </FooterText>
              <span class="material-symbols-outlined footer-icons">
                pin_drop
              </span>
            </FooterTextWithIcon>
            <FooterAnchorTag href={CommonString.us_address_link}>
              <FooterText>{CommonString.us_address}</FooterText>
            </FooterAnchorTag>
          </Div>
          <Div
            style={{
              width: windowWidth < 600 ? "100%" : "25%",
              marginTop: windowWidth < 600 ? "6%" : "0",
            }}
          >
            <FooterTextWithIcon>
              <FooterText is_bold={true.toString()}>
                {CommonString.india}
              </FooterText>
              <span class="material-symbols-outlined footer-icons">
                pin_drop
              </span>
            </FooterTextWithIcon>
            <FooterAnchorTag href={CommonString.india_address_link}>
              <FooterText>{CommonString.prexel_address}</FooterText>
            </FooterAnchorTag>
          </Div>

          <Div
            style={{
              width: windowWidth < 600 ? "100%" : "25%",
              marginTop: windowWidth < 600 ? "6%" : "0",
            }}
          >
            <FooterTextWithIcon>
              <FooterText is_bold={true.toString()}>
                {CommonString.phone}
              </FooterText>
              <span class="material-symbols-outlined footer-icons">call</span>
            </FooterTextWithIcon>
            <FooterAnchorTag href={CommonString.phone_link}>
              <FooterText>{CommonString.phone_number}</FooterText>
            </FooterAnchorTag>
          </Div>
          {/* </Div> */}
        </GridContainer>
        <SocialIconContainer>
          <SocialIconSingleContainer>
            <Icon
              icon="lets-icons:insta"
              fontSize={windowWidth < 600 ? "1rem" : 17}
            />
          </SocialIconSingleContainer>
          <SocialIconSingleContainer>
            <Icon
              icon="ri:meta-line"
              fontSize={windowWidth < 600 ? "1rem" : 17}
            />
          </SocialIconSingleContainer>
          <SocialIconSingleContainer>
            <Icon
              icon="mingcute:twitter-line"
              fontSize={windowWidth < 600 ? "1rem" : 17}
            />
          </SocialIconSingleContainer>
          <SocialIconSingleContainer>
            <Icon
              icon="ic:outline-email"
              fontSize={windowWidth < 600 ? "1rem" : 17}
            />
          </SocialIconSingleContainer>
        </SocialIconContainer>
        <BottomFooterContainer>
          <FooterLinkContainer>
            <span onClick={() => dispatch(onChangeNav(path.privacy_policy))}>
              Privacy Policy
            </span>
            <span
              onClick={() => dispatch(onChangeNav(path.term_and_condition))}
            >
              Terms & Conditions
            </span>
            {/* <span>Cookie Policy</span> */}
          </FooterLinkContainer>
        </BottomFooterContainer>
      </FooterContainer>
    </FooterMainContainer>
  );
}
