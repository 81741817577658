import styled from "styled-components";
import { CommonFont } from "../utils/Font";
import { CommonColor } from "../utils/Color";
import { DeviceSizes } from "./common.style";
import { RecruitementFeatureCardContent } from "./recruitment.style";
import { CommonImages } from "../utils/CommonImages";

const CardImgContainer = styled.div`
  height: 100%;
  aspect-ratio: 1/1;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    aspect-ratio: 1/1;
  }
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
`;

const CardContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const CardImg = styled.img`
  height: 100%;
  width: 100%;
`;
const CardTitle = styled.div`
  color: ${CommonColor.white};
  font-family: Montserrat;
  font-size: ${CommonFont.xl};
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: #fff;

  font-family: Montserrat;
  font-size: ${CommonFont.s};
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.l};
  }
`;

const AboutWrapper = styled.div`
  & .card2 .flex {
    flex-direction: row-reverse;
  }
  @media ${DeviceSizes.mobile} {
    & .card2 .flex {
      flex-direction: column-reverse;
    }
  }
`;
const AboutCardStyled = styled(RecruitementFeatureCardContent)`
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  justify-content: initial;
  align-items: initial;
`;
const AboutCardStyledHeader = styled.div`
  color: #aeaeae;
  font-size: ${CommonFont.semiLB};
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 66.854px */
  margin-bottom: 20px;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xxxl};
  }
`;
const AboutCardStyledHeading = styled.div`
  color: #fff;
  font-size: ${CommonFont.s};
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 37.98px */
  margin-bottom: 5px;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xxl};
  }
`;
const AboutCardStyledText = styled.div`
  color: #acacac;
  font-size: ${CommonFont.ms};
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28.133px */
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vl};
  }
`;
const AboutValuesGridContainer = styled.div`
  perspective: 1000px;
  transform-style: preserve-3d;
  &:hover .card-recruitement .card-border {
    opacity: 1;
  }
  @media ${DeviceSizes.mobile} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const AboutValueHeader = styled.div`
  color: ${CommonColor.white};
  font-size: ${CommonFont.vil};
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 74.961px */
  text-align: center;
  margin-top: 20%;
  margin-bottom: 30px;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xxxl};
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const AboutUsMission = styled.div`
  color: ${CommonColor.white};
  font-size: ${CommonFont.semiLarge};
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 74.961px */
  margin-top: 0.7em;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xl};
    margin-top: ;
  }
`;
const AboutUsMissionText = styled.div`
  color: #bababa;
  font-size: ${CommonFont.s};
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 44.8px */
  margin-top: 30px;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vl};
    margin-top: 5px;
  }
`;
const MissionSvg = styled(CommonImages.about.mission)`
  /* scale: 0.7;
  translate: -15% 0; */
  height: 70px;
`;
const VisionSvg = styled(CommonImages.about.vision)`
  /* scale: 0.7;
  translate: -15% 0; */
  height: 70px;
`;
export {
  VisionSvg,
  MissionSvg,
  AboutUsMissionText,
  AboutUsMission,
  AboutValueHeader,
  AboutValuesGridContainer,
  AboutCardStyledHeading,
  AboutCardStyledText,
  AboutCardStyledHeader,
  AboutCardStyled,
  AboutWrapper,
  CardImgContainer,
  CardContainer,
  CardContentContainer,
  CardImg,
  CardTitle,
  CardContent,
};
