import React, { useEffect, useRef, useState } from "react";
import {
  AddAttachmentContainer,
  ContactFormContainer,
  ContactFormInnerContainer,
  ContactHeading,
  CustomInputContainer,
  MessageStatus,
  MessageSuccessContainer,
} from "../../style/contact.style";
import CustomInput from "../commonComponent/CustomInput";
// import AddAttachement from "../commonComponent/AddAttachement";
import { Div } from "../../style/home.style";
import MagneticHeaderButton from "../commonComponent/MagneticHeaderButton";
import useScreenSize from "../../hooks/WindowSize";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { CommonColor } from "../../utils/Color";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function ContactForm() {
  const parent = useRef();
  const hoverRef = useRef();
  const width = useScreenSize().width;
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [responded, setResponded] = useState("");

  async function SendEmail() {
    setIsLoading(true);
    try {
      var templateParams = {
        from_name: name,
        reply_to: email,
        message: desc,
      };
      await emailjs.send(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_KEY,
        templateParams,
        {
          publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
        }
      );
      setIsLoading(false);
      setResponded("success");
    } catch (err) {
      if (err instanceof EmailJSResponseStatus) {
        console.error("EMAILJS FAILED...", err);
        setIsLoading(false);
        setResponded("failed");
        return;
      }

      console.error("ERROR", err);
      setIsLoading(false);
      setResponded("failed");
    }
  }
  function getEmail(e) {
    setEmail(e.target.value);
  }
  function SetName(e) {
    setName(e.target.value);
  }
  function getDisableSign() {
    if (
      email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/) !== null &&
      name !== "" &&
      desc !== ""
    ) {
      return false;
    }
    return true;
  }
  return (
    <ContactFormContainer>
      <ContactFormInnerContainer>
        <ContactHeading id="header-text">
          <div>
            <div>Hey!</div>
          </div>
          <div>
            <div>Reach out to us</div>
          </div>
        </ContactHeading>
        <CustomInputContainer>
          <CustomInput
            placeholder="Your Name"
            className="input"
            onChange={(e) => SetName(e)}
          />
          <CustomInput
            placeholder="Your Email"
            onChange={(e) => getEmail(e)}
            className="input"
          />
          <CustomInput
            placeholder="How can we help you?"
            className="input"
            onChange={(e) => setDesc(e.target.value)}
          />
        </CustomInputContainer>
        {/* <AddAttachmentContainer id="attachment">
          <AddAttachement multiple="multiple" />
        </AddAttachmentContainer> */}
        <Div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "visible",
            marginTop: width < 600 ? 30 : 20,
            marginLeft: "-10px",
          }}
          ref={parent}
          id="submit-button"
        >
          <MagneticHeaderButton
            ref={hoverRef}
            parent={parent}
            isLoader={isLoading}
            text="Send"
            disable={getDisableSign()}
            onClick={() => (getDisableSign() ? "" : SendEmail())}
          />
          {/* {responded==="success" && } */}
        </Div>
        <MessageSuccessContainer onClick={() => setResponded("")}>
          {responded === "success" && (
            <Icon
              icon="mdi:success"
              color={
                responded === "success"
                  ? CommonColor.success
                  : responded === "failed"
                  ? CommonColor.error
                  : CommonColor.primary_color
              }
              fontSize={18}
              style={{ cursor: "pointer" }}
            />
          )}
          {responded === "failed" && (
            <Icon
              icon="gg:close"
              color={
                responded === "success"
                  ? CommonColor.success
                  : responded === "failed"
                  ? CommonColor.error
                  : CommonColor.primary_color
              }
              fontSize={18}
              style={{ cursor: "pointer" }}
            />
          )}
          <MessageStatus msg={responded}>
            {responded === "success"
              ? "Message sent successfully"
              : responded === "failed"
              ? "Some error occured :("
              : ""}
          </MessageStatus>
        </MessageSuccessContainer>
      </ContactFormInnerContainer>
    </ContactFormContainer>
  );
}
