import React, { useEffect, useRef } from "react";
import {
  RippleCircularButton,
  RippleCircularButtonBg,
} from "../../style/home.style";
import gsap from "gsap";
import { CommonColor } from "../../utils/Color";

export default function RipplePrimaryButton(props) {
  const ref = useRef();
  useEffect(() => {
    if (ref.current === null) return;
    const element = ref.current.querySelector(".ripple");

    gsap.to(element, {
      scale: 0,
    });
    gsap.to(ref.current, {
      color: CommonColor.primary_color,
    });
  }, [ref]);

  function showClickEffect(e) {
    e.preventDefault();
    const element = e.currentTarget.querySelector(".ripple");
    gsap.to(element, {
      x:
        e.clientX -
        element.getBoundingClientRect().left -
        element.getBoundingClientRect().width / 2,
      y:
        e.clientY -
        element.getBoundingClientRect().top -
        element.getBoundingClientRect().height / 2,
      ease: "none",
    });
    gsap.to(element, {
      scale: 1,
      ease: "power.in",
    });
    gsap.to(e.currentTarget, {
      color: CommonColor.white,
      borderColor: CommonColor.secondary_color,
    });
    // gsap.to(element, {
    //   scale: 0,
    //   delay: 1,
    // });
    // gsap.to(e.currentTarget, {
    //   color: CommonColor.white,
    //   delay: 1,
    // });
  }
  function leaveEffect(e) {
    const element = e.currentTarget.querySelector(".ripple");
    gsap.to(element, {
      scale: 0,
    });
    gsap.to(e.currentTarget, {
      color: CommonColor.primary_color,
      borderColor: CommonColor.white,
    });
  }
  return (
    <React.Fragment>
      <RippleCircularButton
        onClick={props.onButtonClick}
        onMouseLeave={leaveEffect}
        onMouseMove={showClickEffect}
        className="ripple-button-primary"
        ref={ref}
        {...props}
      >
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            zIndex: 1,
            height: "max-content",
            width: "max-content",
          }}
        >
          {props.children}
        </span>
        <RippleCircularButtonBg className="ripple"></RippleCircularButtonBg>
      </RippleCircularButton>
    </React.Fragment>
  );
}
