const CommonColor = {
  primary_color: "#263f50",
  secondary_color: "rgba(255, 96, 71, 1)",
  light_secondary: "rgba(255, 96, 71, 0.2)",
  header_color: "rgba(255, 255, 255, 0.2)",
  black: "black",
  footer_gradient_border:
    "linear-gradient( to right, rgba(37, 63, 80, 0), white, rgba(37, 63, 80, 0) ) 1 0 0 0",
  white: "white",
  footer_background: "#F5F5F5",
  grey: "#8E8E8E",
  grey_text: "##b3b3b3",
  dark_primary_color: "#142130",
  transparent: "transparent",
  light_grey: "#d0d0d0",
  scroll_down_border: "linear-gradient(transparent, #ff6600, transparent)",
  we_background_color:
    "linear-gradient(180deg,rgba(255, 102, 0, 0.58) 0%,rgba(255, 102, 0, 0) 100%)",
  dropdown_bg: "rgba(0, 0, 0, 0.20);",
  dropdown_text_bg: "rgba(207, 207, 207, 0.3)",
  dropdown_text_bg_hover: "rgba(255, 255, 255, 0.3)",
  button_bg_magnetic:
    "linear-gradient(180deg,#FF5C00 0%,#FF7863 50%, #FF4747 100%)",
  icon_bg: "#D9D9D9",
  success: "#32cd32",
  error: "red",
};

export { CommonColor };
