import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import Footer from "../../component/commonComponent/Footer";
import StickySocialIcon from "../../component/commonComponent/StickySocialIcon";
import { Cursor, Div } from "../../style/home.style";
import HomePage from "../homeScreen/HomePage";

export default function MainScreen() {
  const cursor = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (cursor.current === null) return;

    gsap.to(cursor.current, {
      scale: 0.17,
      x: -45,
      y: -45,
    });
  }, [cursor]);

  function positionElement(event) {
    const element = document.querySelector(".cursor");
    const x = event.clientX - 30;
    const y = event.clientY - 30;
    gsap.to(element, {
      x: x,
      y: y,
    });
  }
  return (
    <div onMouseMove={(e) => positionElement(e)}>
      <Div style={{ position: "relative" }}>
        <HomePage ref={ref} />

        <StickySocialIcon />
        <Footer />
      </Div>
      <Cursor className="cursor" ref={cursor}>
        <span
          class="material-symbols-outlined"
          style={{
            opacity: 0,
            fontVariationSettings: " 'FILL' 0,'wght' 400,'GRAD' 0,'opsz' 40",
          }}
        >
          arrow_right_alt
        </span>
      </Cursor>
    </div>
  );
}
