import gsap from "gsap";
import React, { useEffect } from "react";
import useScreenSize from "../../hooks/WindowSize";
import { ContactContainer } from "../../style/contact.style";
import { ReviewPageTail } from "../../style/home.style";
import { endLoader } from "../../utils/Functions";
import Footer from "../commonComponent/Footer";
import Header from "../commonComponent/Header";
import StickySocialIcon from "../commonComponent/StickySocialIcon";
import ContactForm from "./ContactForm";
import { onTogglePreLoader } from "../../service/Redux/UserStore";
import { useDispatch } from "react-redux";

export default function Contact() {
  const width = useScreenSize().width;
  const dispatch = useDispatch();
  useEffect(() => {
    const timeline = gsap.timeline({});
    endLoader(timeline);
    dispatch(onTogglePreLoader(false));
    const navbar = document.querySelector(".header");
    const headingTexts = document.querySelectorAll("#header-text>div>div");
    const inputs = document.querySelectorAll(".input");
    const stickySocialIcons = document.querySelectorAll(
      ".social-icon-container.cursor-circle-blast"
    );
    timeline.from(navbar, {
      y: -navbar.clientHeight,
      opacity: 0.4,
      duration: 1,
    });

    headingTexts.forEach((headingText, index) => {
      timeline.from(
        headingText,
        {
          opacity: 0,
          y: headingText.clientHeight,
        },
        "-=50%"
      );
    });
    stickySocialIcons.forEach((stickySocialIcon, index) => {
      timeline.from(
        stickySocialIcon,
        {
          opacity: 0,
          scale: 0.1,
          duration: 0.5,
          ease: "power1.out",
        },
        "-=90%"
      );
    });

    inputs.forEach((input) => {
      timeline.from(
        input,
        {
          y: 20,
          width: 200,
          opacity: 0,
        },
        "-=75%"
      );
    });

    timeline.from(
      "#attachment",
      {
        y: 20,
        opacity: 0,
        duration: 0.2,
      },
      "-=75%"
    );
    timeline.from("#submit-button", {
      y: 20,
      opacity: 0,
      duration: 0.5,
    });
    timeline.to(navbar, {
      y: 0,
      opacity: 1,
    });
    headingTexts.forEach((headingText) => {
      timeline.to(headingText, {
        opacity: 1,
        y: 0,
      });
    });

    inputs.forEach((input) => {
      timeline.to(input, {
        y: 0,
        width: "100%",
        opacity: 1,
      });
    });
    timeline.to(
      "#attachment",
      {
        y: 0,
        opacity: 1,
      },
      "-=100%"
    );
    timeline.to(
      "#submit-button",
      {
        y: 0,
        opacity: 1,
      },
      "-=100%"
    );
  }, []);
  return (
    <React.Fragment>
      <Header />

      <ContactContainer
        style={{
          overflow: "visible",
        }}
      >
        <ContactForm />
        {/*<ScrollRestoration />*/}

        <StickySocialIcon />
        <ReviewPageTail style={{ position: "absolute", bottom: -100 }} />
      </ContactContainer>
      <Footer />
    </React.Fragment>
  );
}
