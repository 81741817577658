import React from "react";
import {
  Div,
  ReviewPageReview,
  ReviewPageReviewContainer,
  ReviewPageTextTitle,
  ReviewPageTitleLineContainer
} from "../../style/home.style";

export default function Review(props) {
  return (
    <ReviewPageReviewContainer className="review-text-container">
      <ReviewPageTitleLineContainer>
        <ReviewPageTextTitle className="review-title">
          <Div>CEO, IT Managed Services Firm - Largo, MD, USA</Div>
        </ReviewPageTextTitle>
      </ReviewPageTitleLineContainer>
      <ReviewPageReview className="review">
        <Div>{props.review.text}</Div>
      </ReviewPageReview>
    </ReviewPageReviewContainer>
  );
}
