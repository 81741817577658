import recruiterWingImg from "../assets/images/recruiterWingImg.png";
import itWingImg from "../assets/images/itWingImg.png";
import bdWingImg from "../assets/images/bdWingImg.png";
import { path } from "./Path";
import { CommonImages } from "./CommonImages";

export const privacy_policy = [
  {
    title: "",
    desc: "Prexel is committed to respecting privacy and to complying with applicable data protection and privacy laws & regulations. This Privacy Policy details how Prexel collects and manages data during its operations and the rights an individual or an entity has in relation to their data. Additional information may be provided regarding this Privacy Policy via notices/supplements. In the event of a conflict between the Privacy Policy and the notices/supplements, the notices/supplements will supersede the Privacy Policy. Individuals or Entities that are not part of Prexel, but work with Prexel to provide products, services, or any other types of collaboration may have their own privacy policies. Prexel is not responsible for the privacy practices of others and recommends that you read their privacy notices carefully.",
  },
  {
    title: "What Data does Prexel collect?",
    desc: "Prexel collects data directly as well as from other available sources to the extent relevant and permitted by applicable local law. Prexel only collects data that is necessary for the requirements of collecting such data and retains said data till it is necessary for the requirements of retaining said data.",
  },
  {
    title: "What will Prexel do with the Data?",
    desc: "Prexel collects, maintains, uses, stores, and otherwise processes the Data for the purpose of performing its operations related to its products/services. Additionally, data may be processed for other purposes after consent and complying with all the laws and regulations applicable. Prexel will only retain data till it is necessary for its operations, and the data will be removed when it is no longer required.",
  },
  {
    title: "How is Sensitive Information treated?",
    desc: "Prexel focuses on limiting the collection of sensitive information and shall only do so if there is a legal justification for collecting and processing it or with express content from the owner of the sensitive information. Prexel recognizes the additional need to protect Sensitive Information. To ensure safety, all Sensitive Information is processed with strict compliance with applicable law and only accessible to a restricted number of individuals who have a clear and justified need to know such information.",
  },
  {
    title: "Who has access to the data?",
    desc: "Prexel has defined appropriate accessibility to ensure that only individuals with the right authority and need, may have access to these data. There are control measures in place limiting the amount of extent to which the data is accessed. So that only the necessary data for operations can be accessed, and the remaining data stays restricted.",
  },
  {
    title: "What steps are taken to safeguard Personal Data?",
    desc: "Prexel prioritizes Privacy and Security, and to ensure successful implementation, there are well-defined policies and guidelines in place. Various activities are undertaken towards securing data which include but are not limited to proactive and reactive risk management, security and privacy engineering, training, and assessments. Online security, physical security, risk of data loss, and other such risks are appropriately addressed while considering the sensitivity of data and the risks related to processing data.",
  },
  {
    title: "Data quality",
    desc: "Appropriate steps are taken to ensure the accuracy of the data we possess and irrelevant data is deleted as they are identified. Prexel also uses public sources to verify data to the extent permitted or required by applicable law or consent.",
  },
  {
    title: "Whom to contact?",
    desc: "For queries regarding data and our privacy policy please contact us at Contact Us",
  },
];
export const services = [
  {
    id: "card_1",
    img: recruiterWingImg,
    buttonLink: path.recruitment,
    title: "Recruitment",
    titleText: "Find the best talent to meet your specific needs",
    inlineTitle1: "Talent Acquisition",
    inlineTitle2: "Recruitment Extension Services",
    inlineTitle1Text: [
      "Contractual Talent Acquisition",
      "Contractual-to-Perm Talent Acquisition",
      "Permanent Talent Acquisition",
    ],
    inlineTitle2Text: [
      "Complete Account Management",
      "In-House Team Recruitment",
      "On-Call Recruitment Services",
    ],
  },
  {
    id: "card_2",
    title: "Digital Solutions",
    img: itWingImg,
    buttonLink: path.it,
    titleText: "Custom Digital Solutions for your business needs",
    inlineTitle1: "Software Development & SEO",
    inlineTitle2: "Business Solutions",
    inlineTitle1Text: [
      "Mobile/Web Development",
      "Custom Software Development",
      "SEO Optimisation",
    ],
    inlineTitle2Text: [
      "Digital Requirement Analysis",
      "Digital Branding",
      "IT Strategic Operation",
    ],
  },
  {
    id: "card_3",
    title: "Business Assistance & Expansion",
    img: bdWingImg,
    buttonLink: path.bae,
    titleText: "Navigating your Business Terrain, every step of the way",
    inlineTitle1: "Business Assistance",
    inlineTitle2: "Business Expansion",
    inlineTitle1Text: [
      "Accounting & Finance Support",
      "IT/HR Support",
      "Professional Customer Support",
    ],
    inlineTitle2Text: [
      "Business Expansion Consulting",
      "Business Process Optimisation",
      "Market Research and Lead Generation",
    ],
  },
  {
    id: "card_4",
    title: "Accounting",
    desc: "Our services span from on-call staffing support to large volume recruitment needs in the IT, Healthcare, Administrative, Accounting, and Finance sectors all over the world.",
    img: bdWingImg,
    buttonLink: path.accounting,
    titleText: "Tailored Solutions for Financial Management",
    inlineTitle1: "Fund Management",
    inlineTitle2: "Accounting & Reporting",
    inlineTitle1Text: [
      "Check & Deposits Processing",
      "Bank Reconciliations",
      "Cash & Bank forecasting",
    ],
    inlineTitle2Text: [
      "Financial Reporting and Analysis Services",
      "Asset and Inventory Management Services",
      "Accounting and Payroll Services",
    ],
  },
];
export const stickyIcon = [
  {
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
          stroke="#253F50"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 11.3701C16.1234 12.2023 15.9812 13.0523 15.5937 13.7991C15.2062 14.5459 14.5931 15.1515 13.8416 15.5297C13.0901 15.908 12.2384 16.0397 11.4077 15.906C10.5771 15.7723 9.80971 15.3801 9.21479 14.7852C8.61987 14.1903 8.22768 13.4229 8.09402 12.5923C7.96035 11.7616 8.09202 10.91 8.47028 10.1584C8.84854 9.40691 9.45414 8.7938 10.2009 8.4063C10.9477 8.0188 11.7977 7.87665 12.63 8.00006C13.4789 8.12594 14.2648 8.52152 14.8716 9.12836C15.4785 9.73521 15.8741 10.5211 16 11.3701Z"
          stroke="#253F50"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.5 6.5H17.51"
          stroke="#253F50"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
          stroke="#253F50"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 3.00005C22.0424 3.67552 20.9821 4.19216 19.86 4.53005C19.2577 3.83756 18.4573 3.34674 17.567 3.12397C16.6767 2.90121 15.7395 2.95724 14.8821 3.2845C14.0247 3.61176 13.2884 4.19445 12.773 4.95376C12.2575 5.71308 11.9877 6.61238 12 7.53005V8.53005C10.2426 8.57561 8.50127 8.18586 6.93101 7.39549C5.36074 6.60513 4.01032 5.43868 3 4.00005C3 4.00005 -1 13 8 17C5.94053 18.398 3.48716 19.099 1 19C10 24 21 19 21 7.50005C20.9991 7.2215 20.9723 6.94364 20.92 6.67005C21.9406 5.66354 22.6608 4.39276 23 3.00005Z"
          stroke="#253F50"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="mail">
          <path
            id="Vector"
            d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
            stroke="#253F50"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M22 6L12 13L2 6"
            stroke="#253F50"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    ),
  },
];
export const review = [
  {
    title: "vision",
    contentList: [
      "People Over Profit",
      "Service over Sales",
      "Virtue over Victory",
      "Quality over quantity",
      "Collboration over Competition",
    ],
    image: CommonImages.about.vision,
  },
  {
    title: "Mission",
    content: "To Promote Expand Elevate people and business",
    image: CommonImages.about.mission,
  },
  {
    title: "Values",
    content:
      "To be the epitome of te best firm to collaborate with and become an important building block of great success stories.",
    image: CommonImages.about.values,
  },
];
export const ServingClientsList = [
  {
    icon: <span class="material-symbols-outlined">newsmode</span>,
    text: "Federal Government",
  },
  {
    icon: <span class="material-symbols-outlined">stadia_controller</span>,
    text: "Entertainment",
  },
  {
    icon: <span class="material-symbols-outlined">digital_wellbeing</span>,
    text: "Health Care",
  },
  {
    icon: <span class="material-symbols-outlined">cell_tower</span>,
    text: "Telecommunication",
  },
  {
    icon: <span class="material-symbols-outlined">assured_workload</span>,
    text: "Federal Government",
  },
  {
    icon: <span class="material-symbols-outlined">connecting_airports</span>,
    text: "Tours & Travels",
  },
  {
    icon: <span class="material-symbols-outlined">attach_money</span>,
    text: "Finance",
  },
  {
    icon: <span class="material-symbols-outlined">real_estate_agent</span>,
    text: "Real Estate",
  },
  {
    icon: <span class="material-symbols-outlined">domain</span>,
    text: "State Government",
  },
  {
    icon: <span class="material-symbols-outlined">garage</span>,
    text: "Automotive",
  },
  {
    icon: <span class="material-symbols-outlined">robot_2</span>,
    text: "Hi-Tech",
  },
  {
    icon: <span class="material-symbols-outlined">account_balance</span>,
    text: "Banking",
  },
  {
    icon: <span class="material-symbols-outlined">shopping_cart</span>,
    text: "FMCG",
  },
  {
    icon: <span class="material-symbols-outlined">home_work</span>,
    text: "Local Government",
  },
];

export const delay = (time) => {
  return new Promise((res) => {
    setTimeout(res, time);
  });
};
