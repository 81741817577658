import gsap from "gsap";
import React, { useEffect } from "react";
import SplitType from "split-type";
import LandingComponent from "../../component/HomeComponent/LandingComponent";
import ReviewPage from "../../component/HomeComponent/ReviewPage";
import ServiceComponent from "../../component/HomeComponent/ServiceComponent";
import Header from "../../component/commonComponent/Header";
import { MainContainer } from "../../style/common.style";
import { endLoader } from "../../utils/Functions";
import { ScrollTrigger } from "gsap/all";
import { onTogglePreLoader } from "../../service/Redux/UserStore";
import { useDispatch } from "react-redux";

const HomePage = ({ ref }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const timeline = gsap.timeline({});
    ScrollTrigger.refresh();
    gsap.to(window, {
      scrollTo: 0,
    });
    endLoader(timeline);
    dispatch(onTogglePreLoader(false));
    const navbar = document.querySelector(".header");
    const animatedTextWe = document.querySelector("div:has(>div>.text) .we");
    const charWeText = new SplitType(animatedTextWe, { types: "chars" });
    const landingPageText = document.querySelector("#landing-page-text");
    const textLines = new SplitType(landingPageText, { types: "lines" });
    const lottieLoader = document.querySelector("#lottie-wrapper");
    const stickySocialIcons = document.querySelectorAll(
      ".social-icon-container.cursor-circle-blast"
    );

    timeline.from(
      navbar,
      {
        y: -navbar.clientHeight,
        opacity: 0.4,
        duration: 1,
        delay: 0.5,
      },
      "-=90%"
    );
    timeline.from(
      charWeText.chars,
      {
        opacity: 0,
        y: animatedTextWe.clientHeight,
      },
      "-=100%"
    );
    timeline.set(textLines.lines, {
      width: "max-content",
    });
    timeline.from(textLines.lines, {
      opacity: 0,
      y: 30,
      stagger: 0.1,
      ease: "power1.out",
      duration: 0.5,
    });

    stickySocialIcons.forEach((stickySocialIcon, index) => {
      timeline.from(
        stickySocialIcon,
        {
          opacity: 0,
          scale: 0.1,
          duration: 0.5,
          ease: "power1.out",
        },
        "-=90%"
      );
    });
    timeline.from(lottieLoader, {
      opacity: 0,
    });
    timeline.to(navbar, {
      y: 0,
      opacity: 1,
    });
    timeline.to(charWeText.chars, {
      opacity: 1,
      y: 0,
      stagger: 0.05,
    });
    // timeline.to(charAnimatedText.lines, {
    //   opacity: 1,
    //   y: 0,
    //   stagger: 0.1,
    // });

    stickySocialIcons.forEach((stickySocialIcon, index) => {
      timeline.to(stickySocialIcon, {
        opacity: 1,
        scale: 1,
      });
    });
    timeline.to(lottieLoader, {
      opacity: 1,
    });

    return () => {
      timeline.kill();
    };
  }, []);

  return (
    <React.Fragment>
      <MainContainer id="homepage">
        <Header />
        <LandingComponent />
        <ServiceComponent />
        <ReviewPage />
      </MainContainer>
    </React.Fragment>
  );
};

export default HomePage;
