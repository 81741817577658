import styled from "styled-components";
import { CommonColor } from "../utils/Color";
import { keyframes } from "styled-components";
import { DeviceSizes } from "./common.style";

var spin = keyframes`
0%   {transform: scale(1);}
50%  {transform: scale(1.04);}
100%  {transform: scale(1);}
`;
const SocialIconContainer = styled.div`
  position: fixed;
  bottom: 20px;
  z-index: 5;
  right: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 20px;
  cursor: pointer;
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;
const SocialIcon = styled.img``;
const CustomIconStyle = styled.button`
  color: ${CommonColor.white} !important;
  background-color: ${CommonColor.primary_color};
  font-size: 14px;
  position: relative;
  display: block;
  overflow: hidden;
  width: auto;
  height: auto;
  border-radius: 50%;
  margin: 10px auto;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  border: 1px solid ${CommonColor.white};
  cursor: pointer;
  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: ${CommonColor.primary_color};
    -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    overflow: hidden;
  }
  svg {
    color: ${CommonColor.white};
    path {
      stroke: ${CommonColor.white};
    }
  }
  &:hover {
    animation-name: ${spin};
    animation-duration: 0.5s;
    transition-timing-function: ease-in-out;
    svg path {
      stroke: ${CommonColor.white} !important;
    }
  }
  &:hover span {
    width: 225%;
    height: 562.5px;
  }
  &:active {
    background-color: ${CommonColor.white};
    overflow: hidden;
  }
`;
const SocialIconName = styled.div`
  z-index: 2;
  position: relative;
`;
const IconContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* border: 1.55px solid #fafafaa8; */
  background-color: ${CommonColor.white};
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;
const IconWrapper = styled.div`
  & path {
    fill: black;
    transition: fill 0.5s ease-in-out;
  }
  &:hover path {
    fill: white;
  }
`;
const SocialIconFillAnimation = styled.div`
  position: fixed;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  background: ${CommonColor.secondary_color};
  z-index: -1;
`;

export {
  IconWrapper,
  SocialIconFillAnimation,
  SocialIconContainer,
  SocialIcon,
  CustomIconStyle,
  SocialIconName,
  IconContainer,
};
