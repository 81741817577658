import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useScreenSize from "../../hooks/WindowSize";
import { DividerLine, FlexContainer } from "../../style/common.style";
import {
  Circle,
  CircleContainer,
  CircleIndividualContainer,
  CircleWrapper,
  Div,
  ReviewDividerContainer,
  ReviewPageContainer,
  ReviewPageReviewWrapper,
  ReviewPageTail,
  ReviewPageTextContainer,
  ReviewPageTitle,
  ReviewPageTitleContainer,
  ReviewPageWrapperWrapper,
} from "../../style/home.style";
import { CommonColor } from "../../utils/Color";
import Review from "./Review";
import CustomDot from "./ReviewCustomDot";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};
const texts = [
  {
    id: 1,
    title: "CEO, IT Consulting and Recruitment firm (Washington DC, US)",
    text: "Over our 24 years of business, we worked with several business development companies, consultants, and specialists. Prexel is the only company who delivered what was promised and more. They have properly assessed our business, cultivated a vision and positioned our company to penetrate other markets beyond our initial goals. I highly recommend any small business that has difficulty navigating current competitive market to wing up with Prexel. The way they conduct business as a whole and like they delivered success to our firm, I am confident they will deliver success to any business with zeal grow.",
  },
  {
    id: 2,
    title: "CEO, IT Managed Services Firm - Largo, MD, USA",
    text: " Discovering Prexel was just the catalyst we needed to “explode”. We were facing a significant challenge with expanding our business.When we discovered Prexel, they were immediately on the move and we saw significant expansion happening right away.They brought along good expertise that involved targeting the right clientele and at the right time.",
  },
];

export default function ReviewPage() {
  const carousel = useRef(null);
  const [current, setCurrent] = useState(0);
  const width = useScreenSize().width;

  useEffect(() => {
    gsap.to(".divider-line-review", {
      startAt: {
        width: 0,
      },
      scrollTrigger: {
        trigger: ".divider-line-review",
        invalidateOnRefresh: true,
        // markers: 1,
        // scrub: true,
      },
      width: "100%",
      duration: 1.5,
    });
  }, []);

  function enableClicker() {
    gsap.to(".cursor", {
      background: CommonColor.white,
      scale: 1,
      opacity: 1,
    });
    gsap.to(".cursor span", {
      opacity: 1,
    });
    // document.querySelector(".cursor").style.mixBlendMode = "exclusion";
  }
  function disableClicker() {
    gsap.to(".cursor", {
      backgroundColor: CommonColor.secondary_color,
      scale: 0.17,
      opacity: 1,
    });
    gsap.to(".cursor span", {
      opacity: 0,
    });
  }

  function resetClickViewer() {
    gsap.to(".cursor", {
      scale: 1,
      delay: 0.2,
    });
  }
  function reduceClickViewer() {
    gsap.to(".cursor", {
      scale: 0.7,
      duration: 0.3,
    });
  }
  return (
    <React.Fragment>
      <ReviewPageContainer>
        <ReviewDividerContainer>
          <DividerLine className="divider-line-review" />
        </ReviewDividerContainer>
        <ReviewPageTitleContainer>
          <ReviewPageTitle>Feedbacks from our clients</ReviewPageTitle>
        </ReviewPageTitleContainer>
        <ReviewPageTextContainer>
          <ReviewPageWrapperWrapper>
            <ReviewPageReviewWrapper className="review-text-wrapper">
              <FlexContainer jc="space-between"></FlexContainer>
              <FlexContainer
                flexDirection={width < 600 ? "column" : ""}
                verticalAlign={width < 600 ? "flex-start" : ""}
              >
                <FlexContainer
                  onMouseEnter={() => enableClicker()}
                  onMouseLeave={() => disableClicker()}
                  width={width < 600 ? "100%" : "calc(100% - 34px)"}
                >
                  <Carousel
                    draggable={true}
                    containerClass="carousel-container"
                    itemClass="carousel-item"
                    infinite={true}
                    showDots={true}
                    customDot={
                      <CustomDot
                        data={texts}
                        SetCurrent={(data) => setCurrent(data)}
                        carousel={carousel}
                      />
                    }
                    responsive={responsive}
                    ref={carousel}
                    arrows={false}
                    slidesToSlide={1}
                    width="100%"
                  >
                    {texts.map((review) => {
                      return (
                        <div
                          onClick={() => {
                            carousel.current.next();
                          }}
                          onMouseDown={() => {
                            reduceClickViewer();
                          }}
                          onMouseUp={() => resetClickViewer()}
                          key={review.id}
                        >
                          <Review review={review} />
                        </div>
                      );
                    })}
                  </Carousel>
                </FlexContainer>
                {/* <CircleWrapper>
                  <CircleContainer>
                    <CircleIndividualContainer
                      $isVisible={current === 0}
                      onClick={() => {
                        carousel.current.goToSlide(0);
                        setCurrent(0);
                      }}
                    >
                      <Circle $isVisible={current === 0} />
                    </CircleIndividualContainer>
                    <CircleIndividualContainer
                      $isVisible={current === 1}
                      onClick={() => {
                        carousel.current.goToSlide(1);
                        setCurrent(1);
                      }}
                    >
                      <Circle $isVisible={current === 1} />
                    </CircleIndividualContainer>
                    <CircleIndividualContainer
                      $isVisible={current === 2}
                      onClick={() => {
                        carousel.current.goToSlide(2);
                        setCurrent(2);
                      }}
                    >
                      <Circle $isVisible={current === 2} />
                    </CircleIndividualContainer>
                  </CircleContainer>
                </CircleWrapper> */}
              </FlexContainer>
            </ReviewPageReviewWrapper>
          </ReviewPageWrapperWrapper>
        </ReviewPageTextContainer>
      </ReviewPageContainer>
      <ReviewPageTail></ReviewPageTail>
    </React.Fragment>
  );
}
