import styled from "styled-components";
import { CommonColor } from "../utils/Color";
import { CommonFont } from "../utils/Font";
import { DeviceSizes } from "./common.style";

const ContactContainer = styled.div`
  background-color: black;
  height: max-content;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  overflow: visible;
  padding-bottom: 200px;
`;
const ContactFormContainer = styled.div`
  padding-left: 29px;
  padding-right: 29px;
  padding-bottom: 20px;
  font-family: "Montserrat";
  background-color: black;
  height: 100%;
  @media ${DeviceSizes.mobile} {
    padding-left: 20px;
    padding-right: 20px;
    height: 100vh;
  }
`;
const ContactHeading = styled.h1`
  font-size: 6em;
  font-weight: 600;
  color: ${CommonColor.white};
  letter-spacing: -0.02em;
  margin: 0;
  padding-top: 8%;
  /* padding-left: 1%; */
  overflow: hidden;
  height: max-content;
  & > div {
    height: max-content;
    width: max-content;
    overflow: hidden;
    margin-left: -3px;
  }
  & > div:nth-last-child(1) {
    color: ${CommonColor.secondary_color};
  }
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xxxl};
  }
`;
const ContactFormInnerContainer = styled.div`
  padding-top: 7%;
  padding-left: 14%;
  /* height: 100%; */
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  border: none;
  @media ${DeviceSizes.mobile} {
    padding-left: 0;
    padding-top: 20%;
  }
`;
const CustomInputContainer = styled.div`
  width: 70%;
  display: flex;
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  flex-direction: column;
  margin-top: 1%;
  transform: scaleY(0.99);
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
const AddAttachmentContainer = styled.div`
  margin-top: 4%;
  @media ${DeviceSizes.mobile} {
    margin-top: 10%;
  }
`;

const MessageStatus = styled.div`
  margin-left: 10px;
  font-size: ${CommonFont.bmb};
  font-weight: 600;
  color: ${(props) =>
    props.msg === "success"
      ? CommonColor.success
      : props.msg === "failed"
      ? CommonColor.error
      : CommonColor.primary_color};
`;
const MessageSuccessContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: translate(10px);
  }
`;
export {
  MessageSuccessContainer,
  MessageStatus,
  AddAttachmentContainer,
  CustomInputContainer,
  ContactContainer,
  ContactFormContainer,
  ContactHeading,
  ContactFormInnerContainer,
};
