import styled from "styled-components";
import { CommonImages } from "../utils/CommonImages";
import { CommonColor } from "../utils/Color";
import { CommonFont } from "../utils/Font";
import { RecruitementFeatureCardContent } from "./recruitment.style";
import { DeviceSizes } from "./common.style";

const BrandingImage = styled(CommonImages.Branding)`
  height: 140px;
`;
const DevelopmentImage = styled(CommonImages.Development)`
  height: 140px;
`;
const BAE1Img = styled(CommonImages.bae.bae_1)`
  height: 140px;
`;
const BAE2Img = styled(CommonImages.bae.bae_2)`
  height: 140px;
  @media ${DeviceSizes.abovetablet} {
    width: 140px;
  }
`;
const ProcessHeaderContainer = styled.h1`
  color: ${CommonColor.white};

  font-size: ${CommonFont.vl};
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 110.954px */
  /* letter-spacing: 0.925px; */
  letter-spacing: -0.05em;
  width: 100%;
  text-align: center;
  margin-top: 20%;
  margin-bottom: 50vh;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xxxl};
    margin-bottom: 0;
  }
`;
const GridContainerInfoProcess = styled.div`
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 50px;
  &:hover .card-recruitement .card-border {
    opacity: 1;
  }
  @media ${DeviceSizes.mobile} {
    margin-left: 6%;
    margin-right: 6%;
  }
`;

const InfoProcessCardContent = styled(RecruitementFeatureCardContent)`
  flex-direction: row;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  align-items: center;
  padding: 50px;
  @media ${DeviceSizes.mobile} {
    flex-direction: column;
    padding: 30px;
  }
`;
const FileSearch = styled(CommonImages.infoProces.fileSearch)`
  width: 200px;
  height: 200px;
`;
const Launch = styled(CommonImages.infoProces.launch)`
  width: 200px;
  height: 200px;
`;
const Maintainence = styled(CommonImages.infoProces.maintainence)`
  width: 200px;
  height: 200px;
`;
const UndrawResult = styled(CommonImages.infoProces.undrawResult)`
  width: 200px;
  height: 200px;
`;
const UserFlow = styled(CommonImages.infoProces.userFlow)`
  width: 200px;
  height: 200px;
`;
const WebsiteBuilder = styled(CommonImages.infoProces.websiteBuilder)`
  width: 200px;
  height: 200px;
`;
const MindMap = styled(CommonImages.infoProces.mindMap)`
  width: 200px;
  height: 200px;
`;
const InfoProcessCardHeading = styled.h3`
  color: ${CommonColor.white};

  font-size: ${CommonFont.m};
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 0;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.xl};
    margin-top: initial;
  }
`;

const InfoCardText = styled.p`
  color: ${CommonColor.grey};
  font-size: ${CommonFont.n};
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-top: 0;
  margin-bottom: 0;
  @media ${DeviceSizes.mobile} {
    font-size: ${CommonFont.vl};
  }
`;

export {
  BAE1Img,
  BAE2Img,
  MindMap,
  InfoCardText,
  InfoProcessCardHeading,
  WebsiteBuilder,
  UserFlow,
  UndrawResult,
  Maintainence,
  Launch,
  FileSearch,
  InfoProcessCardContent,
  BrandingImage,
  DevelopmentImage,
  ProcessHeaderContainer,
  GridContainerInfoProcess,
};
