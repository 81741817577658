import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

function startLoader(timeline) {
  const loaders = gsap.utils.toArray(".loader-container .loader");
  timeline.to(".loader-container", {
    display: "block",
    duration: 0,
  });
  loaders.forEach((loader, index) => {
    timeline.to(
      loader,
      {
        height: "100%",
        duration: 0.7,
        ease: "power3.in",
      },
      index * 0.05
    );
  });
  // loaders.forEach((loader, index) => {
  //   timeline.to(loader, {
  //     duration: 0,
  //   });
  // });
}

function endLoader(timeline) {
  const loaders = gsap.utils.toArray(".loader-container .loader");
  loaders.forEach((loader) => {
    timeline.set(loader, {
      height: "100%",
    });
  });

  loaders.forEach((loader, index) => {
    timeline.to(
      loader,
      {
        height: 0,
        duration: 0.7,
        ease: "power3.in",
        delay: 0.5,
      },
      index * 0.05
    );
  });
  timeline.to(".loader-container", {
    display: "none",
    duration: 0,
  });
  // timeline.to(window, {
  //   scrollTo: 0,
  //   duration: 0,
  // });
  ScrollTrigger.refresh();
}
const NavigateToPath = (navigate, path, timeline) => {
  startLoader(timeline);
  setTimeout(() => {
    navigate(path);
  }, 1800);
};

export { startLoader, endLoader, NavigateToPath };
