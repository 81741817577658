export const CommonFont = {
  xs: "0.75em",
  ms: "0.9375em",
  s: "1em",
  bmb: "1.25em",
  m: "1.5em",
  mSemiB: "1.75em",
  semiLarge: "2em",
  semiLB: "2.25em",
  l: "2.5em",
  vil: "3em",
  vl: "3.5em",
  xil: "4em",
  xl: "4.5em",
  xx: " 5em",
  xxl: "5.5em",
  xxxl: "8.25em",
  xxxxl: "13.1875em",
  n: "1em",
};
