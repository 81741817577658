export const path = {
  home: "/",
  about: "/about",
  service: "/service",
  contact: "/contact",
  privacy_policy: "/privacy-policy",
  term_and_condition: "/sms-terms&conditions",
  recruitment: "/recruitment",
  it: "/it",
  bae: "/business-expansion",
  accounting: "/accounting",
};
