import React from "react";
import ScrollDown from "../../assets/lottie/scroll-down.json";
import LottieLoader from "../../component/lottie/LottieContainer";
import useScreenSize from "../../hooks/WindowSize";
import {
  ContactFormContainer,
  ContactFormInnerContainer,
  ContactHeading,
} from "../../style/contact.style";
import {
  LandingPageText,
  LandingPageTextContainer,
  LottieWrapper,
} from "../../style/home.style";
import { CommonColor } from "../../utils/Color";

export default function RecruitementLanding() {
  const width = useScreenSize().width;
  return (
    <ContactFormContainer style={{ height: "100%" }}>
      <ContactFormInnerContainer
        style={{
          paddingLeft: width < 600 ? "0" : "15%",
        }}
      >
        <ContactHeading
          style={{ color: CommonColor.secondary_color }}
          id="about-us-heading"
        >
          <div>
            <div> Business Assistance & </div>
          </div>
          <div>
            <div>Expansion</div>
          </div>
        </ContactHeading>
        <LandingPageTextContainer>
          <LottieWrapper
            style={{
              display: width < 600 ? "none" : "block",
              lineHeight: "90%",
            }}
            id="lottie-wrapper-recruitement"
          >
            <LottieLoader
              animationData={ScrollDown}
              height={50}
              width={50}
              color="white"
            />
          </LottieWrapper>
          <LandingPageText
            style={{
              width: width < 600 ? "100%" : width < 900 ? "500px" : "100%",
            }}
            id="about-us-text"
          >
            Navigating your Business Terrain, every step of the way
          </LandingPageText>
        </LandingPageTextContainer>
      </ContactFormInnerContainer>
    </ContactFormContainer>
  );
}
