import ReviewPageBG from "../assets/images/ReviewPageBg.png";
import Value from "../assets/images/values.png";
import TalentAquisition from "../assets/images/TalentAquisition.png";
import RecruitementFeatureExtension from "../assets/images/RecruitementFeatureExtension.png";
import BrandingImageP from "../assets/images/Branding.png";
import Devp from "../assets/images/Dev.png";
import { ReactComponent as MenuHeaderBg } from "../assets/images/MenuHeaderBG.svg";
import { ReactComponent as TalentAcquistionDecorator } from "../assets/svgs/TalentAcquistionDecorator.svg";
import { ReactComponent as RecruitementExecutionServiceImg } from "../assets/svgs/Recruitementexecutionserviceimage.svg";
import { ReactComponent as Branding } from "../assets/svgs/Branding.svg";
import { ReactComponent as Development } from "../assets/svgs/Developement.svg";
import { ReactComponent as Mission } from "../assets/svgs/Mission.svg";
import { ReactComponent as Vision } from "../assets/svgs/Vision.svg";
import { ReactComponent as fileSearch } from "../assets/svgs/fileSearch.svg";
import { ReactComponent as userFlow } from "../assets/svgs/user_flow.svg";
import { ReactComponent as websiteBuilder } from "../assets/svgs/website_builder.svg";
import { ReactComponent as undrawResult } from "../assets/svgs/undraw_result.svg";
import { ReactComponent as launch } from "../assets/svgs/launch.svg";
import { ReactComponent as maintainence } from "../assets/svgs/maintainence.svg";
import { ReactComponent as MindMap } from "../assets/svgs/mind_map.svg";
import { ReactComponent as Account_1 } from "../assets/svgs/Account-1.svg";
import { ReactComponent as Account_2 } from "../assets/svgs/Account-2.svg";
import { ReactComponent as BAE_1 } from "../assets/svgs/BAE-1.svg";
import { ReactComponent as BAE_2 } from "../assets/svgs/BAE-2.svg";
export const CommonImages = {
  ReviewPageBG: ReviewPageBG,
  MenuHeaderBg: MenuHeaderBg,
  TalentAcquistionDecorator: TalentAcquistionDecorator,
  RecruitementExecutionServiceImg: RecruitementExecutionServiceImg,
  RecruitementExecutionServiceImgMob: RecruitementFeatureExtension,
  TalentAquisition: TalentAquisition,
  Branding: Branding,
  Development: Development,
  BrandingImageP: BrandingImageP,
  Devp: Devp,
  about: {
    vision: Vision,
    mission: Mission,
    values: Value,
  },
  infoProces: {
    fileSearch: fileSearch,
    userFlow: userFlow,
    websiteBuilder: websiteBuilder,
    undrawResult: undrawResult,
    launch: launch,
    maintainence: maintainence,
    mindMap: MindMap,
  },
  account: {
    acccount_1: Account_1,
    acccount_2: Account_2,
  },
  bae: {
    bae_1: BAE_1,
    bae_2: BAE_2,
  },
};
