import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect } from "react";
import SplitType from "split-type";
import Footer from "../../component/commonComponent/Footer";
import Header from "../../component/commonComponent/Header";
import StickySocialIcon from "../../component/commonComponent/StickySocialIcon";
import { ContactContainer } from "../../style/contact.style";
import { ReviewPageTail } from "../../style/home.style";
import "../../style/recruitement.css";
import { endLoader } from "../../utils/Functions";
import RecruitementGrid from "./RecruitementGrid";
import RecruitementLanding from "./RecruitementLanding";
import RecruitementPara from "./RecruitementPara";
import ServingClients from "./ServingClients";
import { onTogglePreLoader } from "../../service/Redux/UserStore";
import { useDispatch } from "react-redux";

export default function RecruitementPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onTogglePreLoader(false));
    ScrollTrigger.refresh();
    const navbar = document.querySelector(".header");
    const Heading = document.querySelector("#about-us-heading");
    const HeadingTextWords = new SplitType(Heading, { types: "words" }).words;
    const HeadingText = document.querySelector("#about-us-text");
    const HeadingTextLines = new SplitType(HeadingText, { types: "lines" });
    const Icon = document.querySelector("#lottie-wrapper-recruitement");
    const Para = document.querySelector("#recruitement-p-container");
    const animatingNums = document.querySelectorAll(".animating-nums");
    const stickySocialIcons = document.querySelectorAll(
      ".social-icon-container.cursor-circle-blast"
    );

    const timeline = gsap.timeline({});

    endLoader(timeline);

    timeline.from(
      navbar,
      {
        y: -navbar.clientHeight,
        opacity: 0.4,
        duration: 1,
        delay: 0.5,
      },
      "-=90%"
    );
    timeline.from(
      HeadingTextWords,
      {
        opacity: 0.4,
        y: Heading.clientHeight,
      },
      "-=50%"
    );
    timeline.from(
      HeadingTextLines.lines,
      {
        opacity: 0,
        y: 60,
        stagger: 0.1,
      },
      "-=50%"
    );
    timeline.from(
      Icon,
      {
        opacity: 0,
      },
      "-=50%"
    );
    timeline.from(
      Para,
      {
        opacity: 0.2,
      },
      "-=50%"
    );
    stickySocialIcons.forEach((stickySocialIcon, index) => {
      timeline.from(
        stickySocialIcon,
        {
          opacity: 0,
          scale: 0.1,
          duration: 0.5,
          ease: "power1.out",
        },
        "-=90%"
      );
    });
    animatingNums.forEach((numSpan) => {
      const number = parseInt(numSpan.textContent.trim());
      let value = 0;

      const anima = gsap.to(numSpan, {
        scrollTrigger: numSpan,
        onStart: () => {
          numSpan.textContent = 0;
        },
        onUpdate: (animation) => {
          // const progress = currentTime / 1000;
          if (value === number) {
            value = 0;
            anima.kill();
            return;
          }
          value = value + 1;
          numSpan.textContent = value;
        },
        onComplete: () => {
          // Animation completes, set text content to target number
          numSpan.textContent = number;
        },
        duration: 1.5,
        ease: "power1.inOut",
      });
    });
  }, []);

  return (
    <React.Fragment>
      <Header />
      <ContactContainer>
        {/*<ScrollRestoration />*/}

        <RecruitementLanding />
        <RecruitementPara />
        <RecruitementGrid />
        <ServingClients />
        <StickySocialIcon />
        <ReviewPageTail style={{ position: "absolute", bottom: -100 }} />
      </ContactContainer>
      <Footer />
    </React.Fragment>
  );
}
