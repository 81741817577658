import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import useMousePosition from "../../utils/useMousePosition";
import AeroPlane from "../../assets/svgs/AeroPlane";
import { HeaderBttonIconContainer } from "../../style/header.style";
import { CommonColor } from "../../utils/Color";
import gsap from "gsap";
import useScreenSize from "../../hooks/WindowSize";
import { DeviceSizes } from "../../style/common.style";
import { Oval } from "react-loader-spinner";
const Style = styled.a`
  position: relative;
  display: inline-flex;
  justify-content: center;
  /* margin: 0 0.3em; */
  padding: 16px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0;
  letter-spacing: 0.025em;
  color: black;
  background: ${(props) => (props.$disable ? CommonColor.grey : "whitesmoke")};
  /* border: 2px solid black; */
  border-radius: 50px;
  user-select: none;
  cursor: ${(props) => (props.$disable ? "" : "pointer")};
  width: 130px;
  overflow: hidden;
  transition: all 0.75s cubic-bezier(0.52, -0.01, 0.17, 0.75);
  @keyframes changeButtonScale {
    0% {
      transform: translate3d(0);
    }
    100% {
      transform: translate3d(var(--x, 0px), var(--y, 0px), 0);
    }
  }
  @keyframes transitionButton {
    0% {
      transform: scale(1) translate3d(0);
    }
    50% {
      transform: scale(1.05)
        translate3d(calc(var(--x, 0px) / 2), calc(var(--y, 0px) / 2), 0);
    }
    100% {
      transform: scale(1) translate3d(var(--x, 0px), var(--y, 0px), 0);
    }
  }
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  @media ${DeviceSizes.mobile} {
    width: 110px;
    padding: 10px 6px;
  }
`;

export const TextContainer = styled.div`
  font-size: 1rem;
  font-weight: 600;
  /* line-height: 1.25; */
  letter-spacing: 0;
  height: max-content;
  width: max-content;
  color: black;
  transition: color 0.3s ease;
  @media ${DeviceSizes.mobile} {
    font-size: 12px;
    font-weight: 600;
  }
`;
const FillAnimation = styled.div`
  background: ${CommonColor.secondary_color};
  height: 400px;
  width: 400px;
  transform: ${(props) =>
    props.open
      ? "translate(-50%, -50%) scale(1)"
      : "translate(-50%, -50%) scale(0)"};
  transition: transform 0.5s ease-in-out;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform-origin: center;
  border-radius: 50%;
`;

const StyleWrapper = styled.div`
  height: max-content;
  width: max-content;
  padding: 10px;
  margin-top: 2%;
  /* border: 1px solid white; */
  &:hover .buttontext {
    color: ${(props) => (props.$disable ? "black" : "white")};
  }
  &:hover path {
    stroke: ${CommonColor.white};
    transition: stroke 0.3s ease-in-out;
  }
  &:hover rect {
    path: ${CommonColor.white};
    transition: path 0.3s ease-in-out;
  }
  &:hover .buttonfilleffect {
    transform: ${(props) =>
      props.$disable ? "" : "translate(-50%, -50%) scale(1)"};
  }
  @media ${DeviceSizes.mobile} {
    margin-right: 5px;
  }
`;

const MagneticHeaderButton = forwardRef((props, ref) => {
  const windowWidth = useScreenSize().width;
  const [hasMouseEntered, setHasMouseEntered] = useState(false);
  const [pos, setPos] = useState({
    x: 0,
    y: 0,
  });

  function setPosition(e) {
    if (props.disable) return;
    if (hasMouseEntered) return;
    const x = e.clientX - e.currentTarget.getBoundingClientRect().left - 20;
    const y = e.clientY - e.currentTarget.getBoundingClientRect().top - 20;
    const xButtonRight =
      e.clientX - e.currentTarget.getBoundingClientRect().right + 100;
    const yButtonBottom =
      e.clientY - e.currentTarget.getBoundingClientRect().bottom + 60;
    const newX = -x < xButtonRight ? xButtonRight : -x - 100;
    const newY = -y < yButtonBottom ? yButtonBottom : -y - 60;
    gsap.to(e.currentTarget, {
      x: newX * 0.1,
      y: newY * 0.1,
    });
    setPos({
      x: x,
      y: y,
    });
  }
  function handleMoseLeave() {
    if (props.disable) return;
    const cursor = document.querySelector(".cursor");
    gsap.to(cursor, {
      scale: 0.17,
      opacity: 1,
    });
    const node = ref.current;
    node.querySelector("a").style.animationName = "changeButtonScale";
    node.querySelector("a").style.animationIterationCount = "1";
    gsap.to(".cursor-circle-blast:has(.buttonfilleffect)", {
      x: 0,
      y: 0,
    });
  }
  function handleMouseEnter(e) {
    if (props.disable) return;
    setHasMouseEntered(true);
    setTimeout(() => {
      setHasMouseEntered(false);
    }, 500);
    const x = e.clientX - e.currentTarget.getBoundingClientRect().left - 20;
    const y = e.clientY - e.currentTarget.getBoundingClientRect().top - 20;
    const node = ref.current;
    node.querySelector("a").style.animationName = "transitionButton";
    node.querySelector("a").style.animationIterationCount = "1";
    const cursor = document.querySelector(".cursor");
    gsap.from(cursor, {
      scale: 0.17,
      opacity: 1,
      delay: 0,
    });

    gsap.to(cursor, {
      scale: 0.7,
      opacity: 0,
      delay: 0,
      ease: "power.in",
    });
  }
  return (
    <StyleWrapper
      $disable={props.disable}
      onMouseEnter={(e) => {
        handleMouseEnter(e);
      }}
      onMouseLeave={() => {
        handleMoseLeave();
      }}
      onMouseMove={(e) => setPosition(e)}
      className="cursor-circle-blast"
      ref={ref}
    >
      <Style $disable={props.disable} onClick={() => props.onClick()}>
        <FillAnimation
          className="buttonfilleffect"
          open={props.open}
          style={{
            top: pos.y,
            left: pos.x,
          }}
        />
        {/* <HeaderBttonIconContainer>
          <AeroPlane />
        </HeaderBttonIconContainer> */}
        {!props.isLoader && (
          <TextContainer className="buttontext">
            {props.text ?? "Get in Touch"}
          </TextContainer>
        )}
        {props.isLoader && (
          <Oval
            visible={true}
            height="20"
            width="20"
            color="white"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        )}
      </Style>
    </StyleWrapper>
  );
});

export default MagneticHeaderButton;
