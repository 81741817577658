import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { FlexContainer } from "../../style/common.style";
import {
  FileSearch,
  GridContainerInfoProcess,
  InfoCardText,
  InfoProcessCardContent,
  InfoProcessCardHeading,
  Launch,
  Maintainence,
  MindMap,
  ProcessHeaderContainer,
  UndrawResult,
  UserFlow,
  WebsiteBuilder,
} from "../../style/it.style";
import { RecruitmentFeatureCard } from "../../style/recruitment.style";
import useScreenSize from "../../hooks/WindowSize";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

export default function ProcessInfoTech() {
  const windowWidth = useScreenSize().width;
  useEffect(() => {
    const childrens = document.querySelectorAll(
      ".it-process-grid-container>div"
    );
    const title = document.querySelector("#proces-it-title");
    const cardScaleValue = [0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1];
    const spacer = 20;
    if (windowWidth > 600) {
      const chars = new SplitType(title, { type: "chars" }).chars;
      gsap.set(chars, { yPercent: 100, opacity: 0 });
      gsap.to(chars, {
        scrollTrigger: {
          trigger: title,
          start: "top 70%", // Adjust the start trigger position as needed
          end: "bottom 20%", // Adjust the end trigger position as needed
          toggleActions: "play none none reverse",
        },
        yPercent: 0,
        stagger: 0.05,
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      });
      ScrollTrigger.create({
        trigger: title,
        start: "top top+=60",
        endTrigger: childrens[6],
        end: `top-=${6 * spacer} top+=150`,
        pin: true,
        id: "proces-it-title",
        invalidateOnRefresh: true,
        anticipatePin: 1,
        pinSpacing: false,
      });

      gsap.to(title, {
        startAt: {
          scale: 3,
        },
        scrollTrigger: {
          trigger: title,
          start: "top bottom",
          end: "bottom top+=20",
          invalidateOnRefresh: true,
          scrub: true,
        },
        scale: 1,
        ease: "power3.in",
      });
    }

    childrens.forEach((card, index) => {
      if (windowWidth > 600) {
        gsap.set(card, {
          transformOrigin: "top",
          transformPerspective: 1000,
          transformStyle: "preserve-3d",
          filter: "brightness(1)",
        });

        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            start: `top center+=60`,
            endTrigger: childrens[6],
            end: `top top-=90`,
            scrub: true,
            invalidateOnRefresh: true,
          },
          ease: "none",
          scale: cardScaleValue[index],
          rotateX: -10,
          filter: "brightness(0.3)",
        });

        ScrollTrigger.create({
          trigger: card,
          start: `top-=${index * spacer} top+=150`,
          endTrigger: childrens[6],
          end: `top-=${6 * spacer} top+=150`,
          pin: card,
          pinSpacing: false,
          markers: false,
          invalidateOnRefresh: true,
          anticipatePin: 1,
        });
      }
    });

    return () => {
      // Destroy Smooth Scrollbar when the component unmounts
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);
  function movingCursor(event) {
    const cards = document.querySelectorAll(".card-recruitement");
    cards.forEach((card, index) => {
      gsap.to(card, {
        "--mouse-x": event.clientX - card.getBoundingClientRect().left + "px",
        "--mouse-y": event.clientY - card.getBoundingClientRect().top + "px",
      });
    });
  }
  return (
    <div>
      <ProcessHeaderContainer id="proces-it-title">
        How We Work
      </ProcessHeaderContainer>
      <GridContainerInfoProcess>
        <Grid
          container
          spacing={{ xs: 1, sm: 2 }}
          columns={12}
          style={{ justifyContent: "center" }}
          className="it-process-grid-container"
          onMouseMove={(e) => movingCursor(e)}
        >
          <Grid item xs={12} sm={9} style={{ height: "150%" }}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                width: windowWidth < 600 ? "100%" : "",
                height: "100%",
                margin: 0,
              }}
            >
              <div className="card-border"></div>
              <InfoProcessCardContent>
                <FlexContainer
                  jc="start"
                  verticalAlign="center"
                  width="max-content"
                  margin={windowWidth < 600 ? "20px 0 20px 0" : ""}
                >
                  <FileSearch />
                </FlexContainer>
                <FlexContainer
                  margin={windowWidth < 600 ? "0" : "0 0 0 50px"}
                  flexDirection="column"
                  verticalAlign="start"
                >
                  <InfoProcessCardHeading>
                    Research and Discovery
                  </InfoProcessCardHeading>
                  <InfoCardText>
                    Our main priority is to fully understand your business
                    challenge and the idea behind the future solution. We
                    carefully analyze your requirements, research the market and
                    existing competitors, and evaluate the future concept.
                  </InfoCardText>
                </FlexContainer>
              </InfoProcessCardContent>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={9} style={{ height: "150%" }}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                width: windowWidth < 600 ? "100%" : "",
                height: "100%",
                margin: 0,
              }}
            >
              <div className="card-border"></div>
              <InfoProcessCardContent>
                <FlexContainer
                  jc="start"
                  verticalAlign="center"
                  width="max-content"
                  margin={windowWidth < 600 ? "20px 0 20px 0" : ""}
                >
                  <MindMap />
                </FlexContainer>
                <FlexContainer
                  margin={windowWidth < 600 ? "0" : "0 0 0 50px"}
                  flexDirection="column"
                  verticalAlign="start"
                >
                  <InfoProcessCardHeading>
                    Scope Definition
                  </InfoProcessCardHeading>
                  <InfoCardText>
                    We deeply study your objectives in defining the project's
                    features, creating a list of all its modules, and analyzing
                    the project's technical viability. We can now define the
                    solution's tech stack and prepare a project roadmap as a
                    result.
                  </InfoCardText>
                </FlexContainer>
              </InfoProcessCardContent>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={9} style={{ height: "150%" }}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                width: windowWidth < 600 ? "100%" : "",
                height: "100%",
                margin: 0,
              }}
            >
              <div className="card-border"></div>
              <InfoProcessCardContent>
                <FlexContainer
                  jc="start"
                  verticalAlign="center"
                  width="max-content"
                  margin={windowWidth < 600 ? "20px 0 20px 0" : ""}
                >
                  <UserFlow />
                </FlexContainer>
                <FlexContainer
                  margin={windowWidth < 600 ? "0" : "0 0 0 50px"}
                  flexDirection="column"
                  verticalAlign="start"
                >
                  <InfoProcessCardHeading>UI/UX Design</InfoProcessCardHeading>
                  <InfoCardText>
                    We start by defining the information architecture of your
                    mobile or web solution to define possible user flows.
                    Finally, our UX designer prepares a wiferame, clickable
                    prototype that lets us have the first look and feel of the
                    future product.
                  </InfoCardText>
                </FlexContainer>
              </InfoProcessCardContent>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={9} style={{ height: "150%" }}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                width: windowWidth < 600 ? "100%" : "",
                height: "100%",
                margin: 0,
              }}
            >
              <div className="card-border"></div>
              <InfoProcessCardContent>
                <FlexContainer
                  jc="start"
                  verticalAlign="center"
                  width="max-content"
                  margin={windowWidth < 600 ? "20px 0 20px 0" : ""}
                >
                  <WebsiteBuilder />
                </FlexContainer>
                <FlexContainer
                  margin={windowWidth < 600 ? "0" : "0 0 0 50px"}
                  flexDirection="column"
                  verticalAlign="start"
                >
                  <InfoProcessCardHeading>Development</InfoProcessCardHeading>
                  <InfoCardText>
                    First, we create the software architecture and later develop
                    a complete solution, including robust backend and smooth
                    frontend. Adhering to Agile philosophy, we develop in
                    iterations, show demos, and gather feedback.
                  </InfoCardText>
                </FlexContainer>
              </InfoProcessCardContent>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={9} style={{ height: "150%" }}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                width: windowWidth < 600 ? "100%" : "",
                height: "100%",
                margin: 0,
              }}
            >
              <div className="card-border"></div>
              <InfoProcessCardContent>
                <FlexContainer
                  jc="start"
                  verticalAlign="center"
                  width="max-content"
                  margin={windowWidth < 600 ? "20px 0 20px 0" : ""}
                >
                  <UndrawResult />
                </FlexContainer>
                <FlexContainer
                  margin={windowWidth < 600 ? "0" : "0 0 0 50px"}
                  flexDirection="column"
                  verticalAlign="start"
                >
                  <InfoProcessCardHeading>QA & Testing</InfoProcessCardHeading>
                  <InfoCardText>
                    Each interaction is carefully tested to check the product’s
                    security, performance, operation as well as specification
                    compliance. Our goal is to check if the solution works as
                    intended, corresponds to users’ expectations, and remains
                    100% secure.
                  </InfoCardText>
                </FlexContainer>
              </InfoProcessCardContent>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={9} style={{ height: "150%" }}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                width: windowWidth < 600 ? "100%" : "",
                height: "100%",
                margin: 0,
              }}
            >
              <div className="card-border"></div>
              <InfoProcessCardContent>
                <FlexContainer
                  jc="start"
                  verticalAlign="center"
                  width="max-content"
                  margin={windowWidth < 600 ? "20px 0 20px 0" : ""}
                >
                  <Launch />
                </FlexContainer>
                <FlexContainer
                  margin={windowWidth < 600 ? "0" : "0 0 0 50px"}
                  flexDirection="column"
                  verticalAlign="start"
                >
                  <InfoProcessCardHeading>Pre Launch.</InfoProcessCardHeading>
                  <InfoCardText>
                    We perform quality pre-launch tests to ensure smooth and
                    hitch-free product launch. We also provide all essential
                    test case reports, installation guides, app tutorials, and
                    user manuals for easy application delivery.
                  </InfoCardText>
                </FlexContainer>
              </InfoProcessCardContent>
            </RecruitmentFeatureCard>
          </Grid>
          <Grid item xs={12} sm={9} style={{ height: "150%" }}>
            <RecruitmentFeatureCard
              className="card-recruitement"
              style={{
                width: windowWidth < 600 ? "100%" : "",
                height: "100%",
                margin: 0,
              }}
            >
              <div className="card-border"></div>
              <InfoProcessCardContent>
                <FlexContainer
                  jc="start"
                  verticalAlign="center"
                  width="max-content"
                  margin={windowWidth < 600 ? "20px 0 20px 0" : ""}
                >
                  <Maintainence />
                </FlexContainer>
                <FlexContainer
                  margin={windowWidth < 600 ? "0" : "0 0 0 50px"}
                  flexDirection="column"
                  verticalAlign="start"
                >
                  <InfoProcessCardHeading>
                    Post-launch maintenance{" "}
                  </InfoProcessCardHeading>
                  <InfoCardText>
                    Once a product is live, it is important to organize an
                    efficient support process to control the quality of the
                    project, continuously perform security checks, install new
                    updates, and react to users’ feedback and changing business
                    requirements.
                  </InfoCardText>
                </FlexContainer>
              </InfoProcessCardContent>
            </RecruitmentFeatureCard>
          </Grid>
        </Grid>
      </GridContainerInfoProcess>
    </div>
  );
}
