import gsap from "gsap";
import React from "react";
import AnimatedNumber from "react-animated-number";
import { FlexContainer } from "../../style/common.style";
import { TitleLine } from "../../style/home.style";
import { BrandingImage, DevelopmentImage } from "../../style/it.style";
import {
  CardImgContainerRecMobile,
  LinearGradientBg,
  RecruitementFeaturSectionContainer,
  RecruitementFeaturSectionContainerMobile,
  RecruitementFeatureCardContent,
  RecruitementFeatureCardContentBig,
  RecruitementFeatureCardContentContainer,
  RecruitementFeatureCardList,
  RecruitementFeatureCardTitle,
  RecruitementFeatureContainerBig,
  RecruitementFeatureContainerSmall,
  RecruitementFeatureTitle,
  RecruitementMobSvg,
  RecruitmentFeatureBgWrapper,
  RecruitmentFeatureCard,
  RecruitmentFeatureCardBig,
  RecruitmentFeatureNumber,
} from "../../style/recruitment.style";
import { CommonColor } from "../../utils/Color";
import { CommonImages } from "../../utils/CommonImages";

export default function RecruitementGrid() {
  function movingCursor(event) {
    const cards = document.querySelectorAll(".card-recruitement");
    cards.forEach((card, index) => {
      gsap.to(card, {
        "--mouse-x": event.clientX - card.getBoundingClientRect().left + "px",
        "--mouse-y": event.clientY - card.getBoundingClientRect().top + "px",
      });
    });
  }
  function customFormatValue(value, isAppend) {
    if (isAppend) {
      return value.toString() + "+";
    }
    return value.toString();
  }
  return (
    <React.Fragment>
      <RecruitementFeaturSectionContainer onMouseMove={(e) => movingCursor(e)}>
        <RecruitementFeatureContainerSmall>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>
            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              />

              <RecruitementFeatureTitle>
                Mobile Applications
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {" "}
                {/* <span className="animating-nums">15</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={15}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Website Development
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {" "}
                {/* <span className="animating-nums">30</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={30}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </RecruitementFeatureContainerSmall>
        <RecruitementFeatureContainerBig>
          <RecruitmentFeatureCardBig className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContentBig>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "rgba(138, 209, 255, 0.53)" }}
              />

              <RecruitementFeatureCardContentContainer>
                <RecruitementFeatureCardTitle>
                  Software Development & SEO
                </RecruitementFeatureCardTitle>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Mobile/Web Development
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Custom Software Development
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    SEO Optimisation
                  </RecruitementFeatureCardList>
                </FlexContainer>
              </RecruitementFeatureCardContentContainer>
              <RecruitmentFeatureBgWrapper>
                <DevelopmentImage />
              </RecruitmentFeatureBgWrapper>
            </RecruitementFeatureCardContentBig>
          </RecruitmentFeatureCardBig>
          <RecruitmentFeatureCardBig className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContentBig>
              <RecruitementFeatureCardContentContainer>
                <RecruitementFeatureCardTitle>
                  Business Solutions
                </RecruitementFeatureCardTitle>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Digital Requirement Analysis
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Digital Branding
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    IT Strategic Operation
                  </RecruitementFeatureCardList>
                </FlexContainer>
              </RecruitementFeatureCardContentContainer>
              <RecruitmentFeatureBgWrapper>
                <BrandingImage />
              </RecruitmentFeatureBgWrapper>
            </RecruitementFeatureCardContentBig>
          </RecruitmentFeatureCardBig>
        </RecruitementFeatureContainerBig>
        <RecruitementFeatureContainerSmall>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "rgba(138, 209, 255, 0.53)" }}
              />

              <RecruitementFeatureTitle>
                Web Applications
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {" "}
                {/* <span className="animating-nums">20</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={20}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#76401D" }}
              />

              <RecruitementFeatureTitle>
                Digital Branding
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {" "}
                {/* <span className="animating-nums">10</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={10}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </RecruitementFeatureContainerSmall>
      </RecruitementFeaturSectionContainer>
      <RecruitementFeaturSectionContainerMobile>
        <FlexContainer
          flexDirection="column"
          height="max-content"
          padding="30px"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0 20px 22px 20px"
          width="calc(100% - 40px)"
          bg="#191919"
          borderRadius="20px"
        >
          <CardImgContainerRecMobile>
            <RecruitementMobSvg src={CommonImages.Devp} alt="" />
          </CardImgContainerRecMobile>
          <RecruitementFeatureCardContentContainer>
            <RecruitementFeatureCardTitle>
              Software Development & SEO
            </RecruitementFeatureCardTitle>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Mobile/Web Development
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Custom Software Development
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                SEO Optimisation
              </RecruitementFeatureCardList>
            </FlexContainer>
          </RecruitementFeatureCardContentContainer>
        </FlexContainer>

        <FlexContainer
          flexDirection="column"
          height="max-content"
          padding="30px"
          verticalAlign="center"
          boxSizing="border-box"
          margin="20px 20px 20px 20px"
          width="calc(100% - 40px)"
          bg="#191919"
          borderRadius="20px"
        >
          <CardImgContainerRecMobile>
            <RecruitementMobSvg src={CommonImages.BrandingImageP} alt="" />
          </CardImgContainerRecMobile>
          <RecruitementFeatureCardContentContainer>
            <RecruitementFeatureCardTitle>
              Business Solutions
            </RecruitementFeatureCardTitle>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Digital Requirement Analysis
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Digital Branding
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                IT Strategic Operation
              </RecruitementFeatureCardList>
            </FlexContainer>
          </RecruitementFeatureCardContentContainer>
        </FlexContainer>
        <FlexContainer
          flexWrap="wrap"
          height="max-content"
          verticalAlign="center"
          jc="space-between"
          boxSizing="border-box"
          margin="0px 20px 0 20px"
          width="calc(100% - 40px)"
          gap="20px 10px"
        >
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Mobile Applications
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {" "}
                {/* <span className="animating-nums">15</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={15}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Website Development
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {" "}
                {/* <span className="animating-nums">30</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={30}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>{" "}
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Web Applications
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {" "}
                {/* <span className="animating-nums">20</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={20}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>{" "}
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Digital Branding
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {" "}
                {/* <span className="animating-nums">10</span>+ */}
                <AnimatedNumber
                  component="text"
                  value={10}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => customFormatValue(n, true)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </FlexContainer>
      </RecruitementFeaturSectionContainerMobile>
    </React.Fragment>
  );
}
