import { ReactLenis } from "@studio-freight/react-lenis";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import "./App.css";
import RoutesComponent from "./routes/RoutesComponent";
import store from "./service/Redux/store";
import DisableZoom from "./hooks/DisableZoom";

function App() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const bodyElement = document.body;

    const updateThemeColor = () => {
      const computedStyle = window.getComputedStyle(bodyElement);
      const newThemeColor = computedStyle.getPropertyValue("--body-scroll");
      setScroll(newThemeColor.trim() === "1" ? true : false);
    };

    updateThemeColor();

    const observer = new MutationObserver(updateThemeColor);
    observer.observe(bodyElement, { attributes: true });

    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <React.Fragment>
      <DisableZoom />
      {process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_MODE
        : process.env.REACT_APP_PRO_MODE}
      <ReactLenis
        root
        options={{
          duration: 1.2,
          wheelMultiplier: 0.5,
          touchMultiplier: 0.5,
          autoResize: true,
          syncTouch: true,
        }}
      >
        <Provider store={store}>
          <RoutesComponent />
        </Provider>
      </ReactLenis>
    </React.Fragment>
  );
}

export default App;
