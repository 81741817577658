import { createSlice } from "@reduxjs/toolkit";

export const UserStore = createSlice({
  name: "UserStore",
  initialState: {
    location: window.location.pathname,
    preloader_status: true,
  },
  reducers: {
    onChangeNav: (state, action) => {
      state.location = action.payload;
      // console.log(action.payload);
    },
    onTogglePreLoader: (state, action) => {
      state.preloader_status = action.payload;
    },
  },
});

export const { onChangeNav, onTogglePreLoader } = UserStore.actions;

export default UserStore.reducer;
