import React from "react";
import { Circle, CircleIndividualContainer } from "../../style/home.style";

const CustomDot = ({ onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
    data,
    current,
    SetCurrent,
    carousel,
  } = rest;
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <CircleIndividualContainer
      $isVisible={active}
      onClick={() => {
        onClick();
        SetCurrent(currentSlide - 2);
      }}
    >
      <Circle $isVisible={active} />
    </CircleIndividualContainer>
  );
};

export default CustomDot;
