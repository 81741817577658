import React, { useEffect } from "react";
import Header from "../commonComponent/Header";
import { PrivacyScreenContainer } from "../../style/privacy.style";
import { ReviewPageTail } from "../../style/home.style";
import Footer from "../commonComponent/Footer";
import TermsSectionOne from "./TermsSectionOne";
import { endLoader } from "../../utils/Functions";
import gsap from "gsap";
import SplitType from "split-type";
import { onTogglePreLoader } from "../../service/Redux/UserStore";
import { useDispatch } from "react-redux";

export default function Terms() {
  const dispatch = useDispatch();
  useEffect(() => {
    const timeline = gsap.timeline();
    endLoader(timeline);
    dispatch(onTogglePreLoader(false));
    const navbar = document.querySelector(".header");
    const animatedTextWe = document.querySelector("#privacy-header");
    const charWeText = new SplitType(animatedTextWe, { types: "chars" });
    const PrivacyText = document.querySelector("#privacy-text");
    timeline.from(
      navbar,
      {
        y: -navbar.clientHeight,
        opacity: 0.4,
        duration: 1,
        delay: 0.3,
      },
      "-=80%"
    );
    timeline.from(
      charWeText.chars,
      {
        opacity: 0,
        y: charWeText.chars[0].clientHeight,
      },
      "-=150%"
    );
    timeline.from(PrivacyText, {
      opacity: 0,
    });
  }, []);
  return (
    <React.Fragment>
      <PrivacyScreenContainer>
        <Header />
        <TermsSectionOne />
        <ReviewPageTail style={{ position: "absolute", bottom: -100 }} />
      </PrivacyScreenContainer>
      <Footer />
    </React.Fragment>
  );
}
