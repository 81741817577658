import gsap from "gsap";
import React from "react";
import { FlexContainer } from "../../style/common.style";
import { TitleLine } from "../../style/home.style";
import {
  Account1FeatureImg,
  Account2FeatureImg,
  CardImgContainerRecMobile,
  LinearGradientBg,
  RecruitementFeaturSectionContainer,
  RecruitementFeaturSectionContainerMobile,
  RecruitementFeatureCardContent,
  RecruitementFeatureCardContentBig,
  RecruitementFeatureCardContentContainer,
  RecruitementFeatureCardList,
  RecruitementFeatureCardTitle,
  RecruitementFeatureContainerBig,
  RecruitementFeatureContainerSmall,
  RecruitementFeatureTitle,
  RecruitmentFeatureBgWrapper,
  RecruitmentFeatureCard,
  RecruitmentFeatureCardBig,
  RecruitmentFeatureNumber,
} from "../../style/recruitment.style";
import { CommonColor } from "../../utils/Color";
import AnimatedNumber from "react-animated-number";

export default function RecruitementGrid() {
  function movingCursor(event) {
    const cards = document.querySelectorAll(".card-recruitement");
    cards.forEach((card, index) => {
      gsap.to(card, {
        "--mouse-x": event.clientX - card.getBoundingClientRect().left + "px",
        "--mouse-y": event.clientY - card.getBoundingClientRect().top + "px",
      });
    });
  }
  function CustomFormatValue(value, isAppend) {
    if (isAppend) {
      return value.toString() + "+";
    }
    return value.toString();
  }
  return (
    <React.Fragment>
      <RecruitementFeaturSectionContainer
        onMouseMove={(e) => movingCursor(e)}
        style={{ paddingBottom: "20vh" }}
      >
        <RecruitementFeatureContainerSmall>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>
            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ backgroundColor: "#377e89" }}
              />

              <RecruitementFeatureTitle>
                Industries Served
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">5</span> */}
                <AnimatedNumber
                  component="text"
                  value={5}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => CustomFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Procedure to Cash Engagement
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">10</span> */}
                <AnimatedNumber
                  component="text"
                  value={10}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => CustomFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </RecruitementFeatureContainerSmall>
        <RecruitementFeatureContainerBig>
          <RecruitmentFeatureCardBig className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContentBig>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "rgba(138, 209, 255, 0.53)" }}
              />

              <RecruitementFeatureCardContentContainer>
                <RecruitementFeatureCardTitle>
                  Fund Management
                </RecruitementFeatureCardTitle>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Check & Deposits Processing
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Bank Reconciliations
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Cash & Bank forecasting
                  </RecruitementFeatureCardList>
                </FlexContainer>
              </RecruitementFeatureCardContentContainer>
              <RecruitmentFeatureBgWrapper>
                <Account1FeatureImg />
              </RecruitmentFeatureBgWrapper>
            </RecruitementFeatureCardContentBig>
          </RecruitmentFeatureCardBig>
          <RecruitmentFeatureCardBig className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContentBig>
              <RecruitementFeatureCardContentContainer>
                <RecruitementFeatureCardTitle>
                  Accounting & Reporting
                </RecruitementFeatureCardTitle>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Financial Reporting and Analysis Services
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Asset and Inventory Management Services
                  </RecruitementFeatureCardList>
                </FlexContainer>
                <FlexContainer margin="10px 0 0 0">
                  <TitleLine
                    style={{
                      width: "10px",
                      backgroundColor: CommonColor.secondary_color,
                    }}
                  />
                  <RecruitementFeatureCardList>
                    Accounting and Payroll Services
                  </RecruitementFeatureCardList>
                </FlexContainer>
              </RecruitementFeatureCardContentContainer>
              <RecruitmentFeatureBgWrapper>
                <Account2FeatureImg />
              </RecruitmentFeatureBgWrapper>
            </RecruitementFeatureCardContentBig>
          </RecruitmentFeatureCardBig>
        </RecruitementFeatureContainerBig>
        <RecruitementFeatureContainerSmall>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "rgba(138, 209, 255, 0.53)" }}
              />

              <RecruitementFeatureTitle>
                Fund Management Engagements
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                <AnimatedNumber
                  component="text"
                  value={15}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => CustomFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#76401D" }}
              />

              <RecruitementFeatureTitle>
                Accounting & Reporting Engagements
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">15</span> */}
                <AnimatedNumber
                  component="text"
                  value={15}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => CustomFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </RecruitementFeatureContainerSmall>
      </RecruitementFeaturSectionContainer>
      <RecruitementFeaturSectionContainerMobile>
        <FlexContainer
          flexDirection="column"
          height="max-content"
          padding="30px"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0 20px 22px 20px"
          width="calc(100% - 40px)"
          bg="#191919"
          borderRadius="20px"
        >
          <CardImgContainerRecMobile>
            {/* <RecruitementMobSvg src={CommonImages.Devp} alt="" /> */}
            <Account1FeatureImg />
          </CardImgContainerRecMobile>
          <RecruitementFeatureCardContentContainer>
            <RecruitementFeatureCardTitle>
              Fund Management
            </RecruitementFeatureCardTitle>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Check & Deposits Processing
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Bank Reconciliations
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Cash & Bank forecasting
              </RecruitementFeatureCardList>
            </FlexContainer>
          </RecruitementFeatureCardContentContainer>
        </FlexContainer>

        <FlexContainer
          flexDirection="column"
          height="max-content"
          padding="30px"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0 20px 20px 20px"
          width="calc(100% - 40px)"
          bg="#191919"
          borderRadius="20px"
        >
          <CardImgContainerRecMobile>
            <Account2FeatureImg />
          </CardImgContainerRecMobile>
          <RecruitementFeatureCardContentContainer>
            <RecruitementFeatureCardTitle>
              Accounting & Reporting
            </RecruitementFeatureCardTitle>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Financial Reporting and Analysis Services
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Asset and Inventory Management Services
              </RecruitementFeatureCardList>
            </FlexContainer>
            <FlexContainer margin="10px 0 0 0">
              <TitleLine
                style={{
                  width: "10px",
                  width: "7px",
                  backgroundColor: CommonColor.secondary_color,
                }}
              />
              <RecruitementFeatureCardList>
                Accounting and Payroll Services
              </RecruitementFeatureCardList>
            </FlexContainer>
          </RecruitementFeatureCardContentContainer>
        </FlexContainer>
        <FlexContainer
          flexWrap="wrap"
          height="max-content"
          jc="space-between"
          verticalAlign="center"
          boxSizing="border-box"
          margin="0px 20px 0 20px"
          width="calc(100% - 40px)"
          gap="20px 10px"
        >
          <RecruitmentFeatureCard xclassName="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Industries Served
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">5</span> */}
                <AnimatedNumber
                  component="text"
                  value={5}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => CustomFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Procedure To Cash Engagements
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">10</span> */}
                <AnimatedNumber
                  component="text"
                  value={10}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => CustomFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>{" "}
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Fund Management Engagements
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">15</span> */}
                <AnimatedNumber
                  component="text"
                  value={15}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => CustomFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>{" "}
          <RecruitmentFeatureCard className="card-recruitement">
            <div className="card-border"></div>

            <RecruitementFeatureCardContent>
              <LinearGradientBg
                className="bg-card"
                style={{ background: "#377E89" }}
              />

              <RecruitementFeatureTitle>
                Accounting & Reporting Engagements
              </RecruitementFeatureTitle>
              <RecruitmentFeatureNumber>
                {/* <span className="animating-nums">15</span> */}
                <AnimatedNumber
                  component="text"
                  value={15}
                  style={{
                    transition: "0.8s ease-out",
                    fontSize: 48,
                    transitionProperty: "background-color, color, opacity",
                  }}
                  frameStyle={(perc) =>
                    perc === 100
                      ? {}
                      : { backgroundColor: CommonColor.transparent }
                  }
                  duration={300}
                  formatValue={(n) => CustomFormatValue(n, false)}
                />
              </RecruitmentFeatureNumber>
            </RecruitementFeatureCardContent>
          </RecruitmentFeatureCard>
        </FlexContainer>
      </RecruitementFeaturSectionContainerMobile>
    </React.Fragment>
  );
}
