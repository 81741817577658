import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import SplitType from "split-type";
import useScreenSize from "../../hooks/WindowSize";
import { onChangeNav } from "../../service/Redux/UserStore";
import { FlexContainer } from "../../style/common.style";
import {
  CardContainer,
  CardContentTitle,
  CardDetailIndex,
  CardDetailText,
  CardDetailTextBullet,
  CardDetailTextContainer,
  CardDetailTextTitle,
  CardMainContainer,
  CardNumberContainer,
  CardTitle,
  CardWrapper,
  Div,
  DividerLine,
  ServiceWrapper,
  ServicesTitle,
  TitleWrapperContainer,
} from "../../style/home.style";
import { CommonString } from "../../utils/CommonString";
import { services } from "../../utils/MockData";
import RipplePrimaryButton from "../commonComponent/RipplePrimaryButton";
gsap.registerPlugin(ScrollTrigger);

export default function ServiceComponent() {
  const wrapper = useRef(null);
  const titleRef = useRef(null);
  const { width } = useScreenSize();
  const dispatch = useDispatch();
  const scrollbarRef = useRef(null);

  useEffect(() => {
    const cards = gsap.utils.toArray(".card");
    // only fire callbacks when the active state toggles
    ScrollTrigger.config({
      limitCallbacks: true,
      ignoreMobileResize: true,
    });

    if (width > 900) {
      const chars = new SplitType(titleRef.current, { type: "chars" }).chars;
      gsap.set(chars, { yPercent: 100, opacity: 0 });
      gsap.to(chars, {
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 70%", // Adjust the start trigger position as needed
          end: "bottom 20%", // Adjust the end trigger position as needed
          toggleActions: "play none none reverse",
        },
        yPercent: 0,
        stagger: 0.05,
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      });

      gsap.to(titleRef.current, {
        startAt: {
          scale: 3,
        },
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top bottom",
          end: "bottom top+=60",
          invalidateOnRefresh: true,
          scrub: true,
        },
        scale: 1,
        ease: "power3.in",
      });

      ScrollTrigger.create({
        trigger: titleRef.current,
        start: `top top+=65`,
        endTrigger: cards[0],
        end: `top top+=270`,
        pin: true,
        pinSpacing: false,
        id: "title-services",
        invalidateOnRefresh: true,
        anticipatePin: 1,
        markers: false,
      });
      // Create ScrollTrigger for fading the container
      gsap.to(".title-wrapper-container", {
        opacity: 0,
        scrollTrigger: {
          trigger: cards[0],
          start: "top bottom",
          end: "top top+=270",
          scrub: true,
          invalidateOnRefresh: true,
        },
        ease: "none",
        duration: 1,
      });

      const spacer = 20;

      cards.forEach((card, index) => {
        const cardScaleValue = [0.55, 0.7, 0.85, 1];
        gsap.set(card, {
          transformOrigin: "top",
          transformPerspective: 1000,
          transformStyle: "preserve-3d",
          filter: "brightness(1)",
        });

        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            start: `top center-=150`,
            endTrigger: cards[3],
            end: `top top+=90`,
            scrub: true,
            invalidateOnRefresh: true,
          },
          ease: "none",
          scale: cardScaleValue[index],
          rotateX: -10,
          filter: "brightness(0.3)",
        });

        ScrollTrigger.create({
          trigger: card,
          start: `top-=${index * spacer} top+=90`,
          endTrigger: cards[3],
          end: `top top+=90`,
          pin: true,
          pinSpacing: false,
          markers: false,
          invalidateOnRefresh: true,
          anticipatePin: 1,
        });
      });
    } else {
      // cards.forEach((card, index) => {
      // gsap.set(card, {
      //   transformOrigin: "top",
      //   transformPerspective: 1000,
      //   transformStyle: "preserve-3d",
      //   filter: "brightness(1)",
      // });
      // gsap.to(card, {
      //   scrollTrigger: {
      //     trigger: card,
      //     start: `top center-=60`,
      //     scrub: true,
      //     markers: false,
      //     invalidateOnRefresh: true,
      //   },
      //   ease: "none",
      //   filter: "brightness(0.3)",
      // });
      // });
    }

    return () => {
      // Destroy Smooth Scrollbar when the component unmounts
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, [width]);

  return (
    <ServiceWrapper className="wrapper" ref={wrapper}>
      <TitleWrapperContainer
        className="title-wrapper-container"
        ref={scrollbarRef}
      >
        <ServicesTitle
          className="title-services"
          ref={titleRef}
          id="title-services"
        >
          {CommonString.services.toUpperCase()}
        </ServicesTitle>
        <DividerLine
          className="line-separator"
          style={{ display: width < 900 ? "none" : "" }}
        />
      </TitleWrapperContainer>
      <CardWrapper className="card-container">
        {services.map((service, index) => (
          <CardMainContainer
            key={service.id}
            className={`card card${index + 1}`}
          >
            <CardContainer>
              <svg class="svg-container"></svg>
              <FlexContainer
                height={width < 600 ? "max-content" : "calc(100% - 90px)"}
                padding={width < 600 ? "20px" : "45px"}
                flexDirection={width < 600 ? "column" : ""}
                width={width < 600 ? "calc(100%-40px)" : "calc(100% - 90px)"}
                jc="space-between"
                verticalAlign={width < 600 ? "flex-start" : "flex-start"}
              >
                <FlexContainer
                  flexDirection="column"
                  verticalAlign="flex-start"
                  jc="space-between"
                  height="100%"
                  width={width < 600 ? "100%" : "55%"}
                >
                  <Div>
                    <CardNumberContainer>
                      {"0" + (index + 1)}
                    </CardNumberContainer>
                    <CardTitle>{service.title}.</CardTitle>
                  </Div>
                  <RipplePrimaryButton
                    style={{ display: width < 600 ? "none" : "" }}
                    onButtonClick={() => {
                      dispatch(onChangeNav(service.buttonLink));
                    }}
                  >
                    Explore
                  </RipplePrimaryButton>
                </FlexContainer>
                <FlexContainer
                  flexDirection="column"
                  jc="space-between"
                  verticalAlign="flex-start"
                  margin={width < 600 ? "5px 0 0 0" : "90px 45px 0px 90px"}
                  width={width < 600 ? "100%" : "calc(100% - 135px)"}
                  height="calc(100% - 90px)"
                >
                  <CardContentTitle>{service.titleText}</CardContentTitle>
                  <FlexContainer
                    jc="space-between"
                    verticalAlign="flex-start"
                    width="100%"
                    margin={width < 600 ? "30px 0 0 0" : "30px 0 0 0"}
                    flexWrap={width < 600 ? "wrap" : "no-wrap"}
                  >
                    <FlexContainer verticalAlign="flex-start">
                      <CardDetailIndex>01</CardDetailIndex>
                      <CardDetailTextContainer>
                        <CardDetailTextTitle>
                          {service.inlineTitle1}
                        </CardDetailTextTitle>
                        <CardDetailText>
                          {service.inlineTitle1Text.map((text) => (
                            <CardDetailTextBullet>{text}</CardDetailTextBullet>
                          ))}
                        </CardDetailText>
                      </CardDetailTextContainer>
                    </FlexContainer>
                    <FlexContainer
                      verticalAlign="flex-start"
                      margin={width < 600 ? "30px 0 0 0" : "0"}
                    >
                      <CardDetailIndex>02</CardDetailIndex>
                      <CardDetailTextContainer>
                        <CardDetailTextTitle>
                          {service.inlineTitle2}
                        </CardDetailTextTitle>
                        <CardDetailText>
                          {service.inlineTitle2Text.map((text) => (
                            <CardDetailTextBullet>{text}</CardDetailTextBullet>
                          ))}
                        </CardDetailText>
                      </CardDetailTextContainer>
                    </FlexContainer>
                  </FlexContainer>
                  <RipplePrimaryButton
                    onButtonClick={(e) => {
                      dispatch(onChangeNav(service.buttonLink));
                    }}
                    style={{
                      display: width < 600 ? "" : "none",
                      marginTop: "30px",
                    }}
                  >
                    Explore
                  </RipplePrimaryButton>
                </FlexContainer>
              </FlexContainer>
            </CardContainer>
          </CardMainContainer>
        ))}
      </CardWrapper>
    </ServiceWrapper>
  );
}
