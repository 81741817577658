import React from "react";
import Lottie from "react-lottie";

export default function LottieLoader(props) {
  const defaultOptions = {
    loop: props.loop ?? true,
    autoplay: props.autoPlay ?? true,
    animationData: props.animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    color: props.color,
  };
  return (
    <Lottie
      options={defaultOptions}
      height={props.height ?? 200}
      width={props.width ?? 200}
      isStopped={false}
      isPaused={false}
    />
  );
}
