import React from "react";
import {
  ContactFormContainer,
  ContactFormInnerContainer,
  ContactHeading,
} from "../../style/contact.style";
import {
  LandingPageText,
  LandingPageTextContainer,
  LottieWrapper,
} from "../../style/home.style";
import { CommonColor } from "../../utils/Color";
import Vision from "./Vision";
import useScreenSize from "../../hooks/WindowSize";
import LottieLoader from "../lottie/LottieContainer";
import ScrollDown from "../../assets/lottie/scroll-down.json";

export default function AboutUsPage() {
  const width = useScreenSize().width;
  return (
    <ContactFormContainer
      style={{
        height: "100%",
        margin: width < 600 ? "0" : "0 60px 0 60px",
        paddingBottom: "100px",
      }}
    >
      <ContactFormInnerContainer
        style={{
          height: "calc(100vh - 15%)",
          paddingLeft: width < 600 ? "20px" : "15%",
        }}
      >
        <ContactHeading
          style={{ color: CommonColor.secondary_color }}
          id="about-us"
        >
          About Us
        </ContactHeading>
        <LandingPageTextContainer>
          <LottieWrapper
            style={{ display: width < 600 ? "none" : "block" }}
            id="lottie-wrapper"
          >
            <LottieLoader
              animationData={ScrollDown}
              height={50}
              width={50}
              color="white"
            />
          </LottieWrapper>
          <LandingPageText
            style={{
              width: width < 600 ? "100%" : width < 900 ? "400px" : "100%",
            }}
            id="about-us-text"
          >
            We are a consulting firm Promoting, Expanding, and Elevating people
            and businesses across the world in the digital and real world with
            our IT, Recruitment, Business Expansion, and Accounting Services.
          </LandingPageText>
        </LandingPageTextContainer>
      </ContactFormInnerContainer>
      <Vision />
    </ContactFormContainer>
  );
}
