import React from "react";
import {
  Loader,
  LoaderContainer,
  LoaderInnerContainer,
} from "../../style/common.style";

export default function PageLoader() {
  return (
    <React.Fragment>
      <LoaderContainer className="loader-container">
        <LoaderInnerContainer>
          <Loader className="loader" $index={0}></Loader>
          <Loader className="loader" $index={1}></Loader>
          <Loader className="loader" $index={2}></Loader>
        </LoaderInnerContainer>
      </LoaderContainer>
    </React.Fragment>
  );
}
