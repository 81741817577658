import React, { useEffect, useRef } from "react";
import {
  IconContainer,
  IconWrapper,
  SocialIconContainer,
  SocialIconFillAnimation,
} from "../../style/socialIcon.style";

import { Icon } from "@iconify/react";
import gsap from "gsap";
import useMousePosition from "../../utils/useMousePosition";
import { CommonColor } from "../../utils/Color";
export default function StickySocialIcon() {
  const { mouseX, mouseY } = useMousePosition();
  const refM = useRef(null);
  const refF = useRef(null);
  const refI = useRef(null);
  useEffect(() => {
    if (refI.current === null) return;
    const boxMeasurenmentI = refI.current.getBoundingClientRect();
    const distanceToTriggerXI = boxMeasurenmentI.width * 0.5;
    const distanceToTriggerYI = boxMeasurenmentI.height * 0.5;
    const mousePosX = mouseX;
    const mousePosY = mouseY;

    const buttonCenterYI = boxMeasurenmentI.top + boxMeasurenmentI.height / 2;
    const buttonCenterXI = boxMeasurenmentI.left + boxMeasurenmentI.width / 2;

    const distanceMouseButtonXI = Math.abs(mousePosX - buttonCenterXI);
    const distanceMouseButtonYI = Math.abs(mousePosY - buttonCenterYI);

    if (
      distanceMouseButtonXI < distanceToTriggerXI &&
      distanceMouseButtonYI < distanceToTriggerYI
    ) {
      const x = mouseX - (boxMeasurenmentI.left + boxMeasurenmentI.width / 2);
      const y = mouseY - (boxMeasurenmentI.top + boxMeasurenmentI.height / 2);
      gsap.to(refI.current, {
        x: x * 0.2,
        y: y * 0.2,
      });
      gsap.to(refI.current.querySelector(".social-icon-bg"), {
        x: x,
        y: y,
        scale: 1,
        duration: 1,
      });
      gsap.to(".cursor", {
        opacity: 0,
        scale: 1,
        easing: "expo-in",
        duration: 0.5,
      });
    } else if (
      distanceMouseButtonXI > distanceToTriggerXI ||
      distanceMouseButtonYI > distanceToTriggerYI
    ) {
      gsap.to(refI.current, {
        x: 0,
        y: 0,
      });

      gsap.to(refI.current.querySelector(".social-icon-bg"), {
        scale: 0,
      });
    }
  }, [mouseX, mouseY, refI]);
  useEffect(() => {
    if (refF.current === null) {
      return;
    }
    const boxMeasurenmentF = refF.current.getBoundingClientRect();
    const distanceToTriggerXF = boxMeasurenmentF.width * 0.5;
    const distanceToTriggerYF = boxMeasurenmentF.height * 0.5;
    const mousePosX = mouseX;
    const mousePosY = mouseY;

    const buttonCenterYF = boxMeasurenmentF.top + boxMeasurenmentF.height / 2;
    const buttonCenterXF = boxMeasurenmentF.left + boxMeasurenmentF.width / 2;

    const distanceMouseButtonXF = Math.abs(mousePosX - buttonCenterXF);
    const distanceMouseButtonYF = Math.abs(mousePosY - buttonCenterYF);
    if (
      distanceMouseButtonXF < distanceToTriggerXF &&
      distanceMouseButtonYF < distanceToTriggerYF
    ) {
      const x = mouseX - (boxMeasurenmentF.left + boxMeasurenmentF.width / 2);
      const y = mouseY - (boxMeasurenmentF.top + boxMeasurenmentF.height / 2);
      gsap.to(refF.current, {
        x: x * 0.2,
        y: y * 0.2,
      });
      gsap.to(refF.current.querySelector(".social-icon-bg"), {
        x: x,
        y: y,
        scale: 1,
        duration: 1,
      });
      gsap.to(".cursor", {
        opacity: 0,
        scale: 1,
        easing: "expo-in",
        duration: 0.5,
      });
    } else if (
      distanceMouseButtonXF > distanceToTriggerXF ||
      distanceMouseButtonYF > distanceToTriggerYF
    ) {
      gsap.to(refF.current, {
        x: 0,
        y: 0,
      });

      gsap.to(refF.current.querySelector(".social-icon-bg"), {
        scale: 0,
      });
    }
  }, [mouseX, mouseY, refF]);
  useEffect(() => {
    if (refM.current === null) return;
    const boxMeasurenmentM = refM.current.getBoundingClientRect();
    const distanceToTriggerXM = boxMeasurenmentM.width * 0.5;
    const distanceToTriggerYM = boxMeasurenmentM.height * 0.5;
    const mousePosX = mouseX;
    const mousePosY = mouseY;

    const buttonCenterYM = boxMeasurenmentM.top + boxMeasurenmentM.height / 2;
    const buttonCenterXM = boxMeasurenmentM.left + boxMeasurenmentM.width / 2;

    const distanceMouseButtonXM = Math.abs(mousePosX - buttonCenterXM);
    const distanceMouseButtonYM = Math.abs(mousePosY - buttonCenterYM);
    if (
      distanceMouseButtonXM < distanceToTriggerXM &&
      distanceMouseButtonYM < distanceToTriggerYM
    ) {
      const x = mouseX - (boxMeasurenmentM.left + boxMeasurenmentM.width / 2);
      const y = mouseY - (boxMeasurenmentM.top + boxMeasurenmentM.height / 2);
      gsap.to(refM.current, {
        x: x * 0.2,
        y: y * 0.2,
      });
      gsap.to(refM.current.querySelector(".social-icon-bg"), {
        x: x,
        y: y,
        scale: 1,
        duration: 1,
      });

      gsap.to(".cursor", {
        opacity: 0,
        scale: 1,
        easing: "expo-in",
        duration: 0.5,
      });
    } else if (
      distanceMouseButtonXM > distanceToTriggerXM ||
      distanceMouseButtonYM > distanceToTriggerYM
    ) {
      gsap.to(refM.current, {
        x: 0,
        y: 0,
      });

      gsap.to(refM.current.querySelector(".social-icon-bg"), {
        scale: 0,
      });
    }
  }, [mouseX, mouseY, refM]);
  function disableBlast() {
    gsap.to(".cursor", {
      opacity: 1,
      scale: 0.17,
    });
  }

  return (
    <SocialIconContainer onMouseLeave={() => disableBlast()}>
      <IconWrapper>
        <IconContainer
          href="mailto:prem@prexelllc.com?subject=Subject&body=Body%20goes%20here"
          target="_blank"
          className="social-icon-container cursor-circle-blast"
          ref={refM}
        >
          <SocialIconFillAnimation className="social-icon-bg" />
          <Icon
            icon="mdi:email-outline"
            color={CommonColor.primary_color}
            strokeWidth="2"
            fontSize={18}
          />
        </IconContainer>
      </IconWrapper>
      <IconWrapper>
        <IconContainer
          href="https://www.facebook.com/prexel.win"
          target="_blank"
          className="social-icon-container cursor-circle-blast"
          ref={refF}
        >
          <SocialIconFillAnimation className="social-icon-bg" />
          <Icon
            icon="uil:facebook-f"
            color={CommonColor.primary_color}
            strokeWidth="2"
            fontSize={18}
          />
        </IconContainer>
      </IconWrapper>
      <IconWrapper>
        <IconContainer
          href="https://www.instagram.com/prexel.win"
          target="_blank"
          className="social-icon-container cursor-circle-blast"
          ref={refI}
        >
          <SocialIconFillAnimation className="social-icon-bg" />
          <Icon
            icon="uil:instagram"
            color={CommonColor.primary_color}
            strokeWidth="2"
            fontSize={18}
          />
        </IconContainer>
      </IconWrapper>
    </SocialIconContainer>
  );
}
