import React from "react";
import {
  ServingClientIconContainer,
  ServingClientText,
  ServingClientsContainer,
  ServingClientsTitle,
  Slider,
  SliderContainer,
  SliderWrapper,
} from "../../style/recruitment.style";

import useScreenSize from "../../hooks/WindowSize";
import { FlexContainer } from "../../style/common.style";
import { ServingClientsList } from "../../utils/MockData";

export default function ServingClients() {
  const width = useScreenSize().width;
  return (
    <ServingClientsContainer>
      <ServingClientsTitle>Industries We Serve</ServingClientsTitle>
      <SliderWrapper>
        <SliderContainer>
          <Slider>
            {ServingClientsList.map((servingClient, index) => {
              return (
                <FlexContainer
                  jc="flex-start"
                  flexDirection={width < 600 ? "column" : ""}
                  verticalAlign={width < 600 ? "center" : "center"}
                  margin={width < 600 ? "0 10px" : ""}
                  className="carousel-element"
                  width={width < 600 ? "130px" : "max-content"}
                  key={index}
                >
                  <ServingClientIconContainer>
                    {servingClient.icon}
                  </ServingClientIconContainer>
                  <ServingClientText>{servingClient.text}</ServingClientText>
                </FlexContainer>
              );
            })}
            {ServingClientsList.map((servingClient, index) => {
              return (
                <FlexContainer
                  jc="flex-start"
                  flexDirection={width < 600 ? "column" : ""}
                  verticalAlign={width < 600 ? "center" : "center"}
                  margin={width < 600 ? "0 10px" : ""}
                  width={width < 600 ? "130px" : "max-content"}
                  className="carousel-element"
                  key={index}
                >
                  <ServingClientIconContainer>
                    {servingClient.icon}
                  </ServingClientIconContainer>
                  <ServingClientText>{servingClient.text}</ServingClientText>
                </FlexContainer>
              );
            })}
          </Slider>
        </SliderContainer>
      </SliderWrapper>
    </ServingClientsContainer>
  );
}
